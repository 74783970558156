import React from "react";

import {  IconButton,TextField,InputAdornment,OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff, AccountCircle } from "@mui/icons-material";

import Button from "@mui/material/Button";
import { InputLabel, Grid } from "@mui/material";

export default function SahoForgotPasswordForm({
  state,
  error,
  submitForm,
  handleProfileChange,
  handleClickShowNewPassword,
  handleMouseDownNewPassword,
  handleClickShowConfirmPassword,
  handleMouseDownConfirmPassword,
}) {
  return (
    <div className="login_mainContainer">
      <div className="login_table">
        <h3>New Password</h3>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          columns={1}
          spacing={1}
          item
          xs={8}
        >
          <Grid>
            <OutlinedInput
              style={{ marginTop: "25px", marginLeft: "25px" }}
              id="outlined-adornment-password"
              type={state.showNewPassword ? 'text' : 'password'}
              placeholder="New Password"
              variant="outlined"
              fullwidth
              value={state.newpassword}
              onChange={handleProfileChange("newpassword")}
              error={error.newpasswordErr ? true : false}
              helperText={error.newpasswordErr}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //       aria-label="toggle password visibility"
              //       onClick={handleClickShowNewPassword}
              //       onMouseDown={handleMouseDownNewPassword}
              //       edge="end"
              //     >
              //       {state.showNewPassword ? <VisibilityOff /> : <Visibility />}
              //     </IconButton>
              //   </InputAdornment>
              // }
            />
          </Grid>
          <Grid>
            <OutlinedInput
              style={{ marginTop: "25px", marginLeft: "25px" }}
              id="outlined-adornment-password"
              type={state.showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              variant="outlined"
              fullwidth
              value={state.confirmpassword}
              onChange={handleProfileChange("confirmpassword")}
              error={error.confirmpasswordErr ? true : false}
              helperText={error.confirmpasswordErr}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //       aria-label="toggle password visibility"
              //       onClick={handleClickShowConfirmPassword}
              //       onMouseDown={handleMouseDownConfirmPassword}
              //       edge="end"
              //     >
              //       {state.showPassword ? <VisibilityOff /> : <Visibility />}
              //     </IconButton>
              //   </InputAdornment>
              // }
            />
          </Grid>
        </Grid>

        <Button
          style={{
            marginTop: "25px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
          onClick={submitForm}
          variant="contained"
        >
          Update
        </Button>
      </div>
    </div>
  );
}
