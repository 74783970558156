import React, { useState } from "react";
import { submitTaxDetails } from "./SahoService";
import SahoTaxesFormBoot from "./Forms/SahoTaxesFormBoot";


const SahoTaxes = () => {
  const [rows, updateRows] = useState([]);
  const [taxName, setTaxName] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");
  const [editrow, setEditRow] = useState({});
  const [editIndex, setEditIndex] = useState("");
  const [taxCount, updateTaxCount] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [tableContentError, setTableContentError] = useState("");
  const [data, updatedData] = useState({
    taxName: "",
    taxCode: "",
    taxPercentage: "",
  });

  const handleEditChange = (prop) => (event) => {
    setEditRow({ ...editrow, [prop]: event.target.value });
  };
  const handleEditingDone = () => {
    let taxArray = rows;
    taxArray[editIndex] = editrow;
    updateRows(taxArray);
    setEditRow({});
    setEditIndex("");
    setNotify({
      isOpen: true,
      message: "Edited Successfully",
      type: "success",
    });
    
  };
  const handleCancel = () => {
    setEditRow({});
    setEditIndex("");
    
  };

  // Function to make the selected row of the table editable
  const handleEditing = (row) => {
    setEditRow(row);
    let index = rows.findIndex((x) => x.taxName === row.taxName);
    setEditIndex(index);
   
  };
  //Function for deleting the selected row of the table
  const handleDeleting = (row) => {
    let originalRows = rows;
    let taxName = row.taxName;
    let newTaxArray = originalRows.filter((tax) => tax.taxName !== taxName);
    let newTaxArrayCount = newTaxArray.length;
    updateRows(newTaxArray);
    updateTaxCount(newTaxArrayCount);
    setNotify({
      isOpen: true,
      message: "Remove Successfully",
      type: "success",
    });
  };

  // Function to add the tax details in the table
  const addTax = () => {
    let dataArr = [];
    const taxPercentageValidation = /[+-]?([0-9]*[.])?[0-9]+/;
    if (taxName === "" || taxName === undefined) {
      setTableContentError("Please enter the Tax name");
    } else if (taxCode === "" || taxCode === undefined) {
      setTableContentError("Please enter the Tax Code");
    } else if (taxPercentage === "" || taxPercentage === undefined) {
      setTableContentError("Invalid Tax percentage");
    } else if (!taxPercentageValidation.test(taxPercentage)) {
      setTableContentError("The tax percentage you entered is invalid");
    } else {
      setTableContentError("");
      let data = {
        taxName: taxName,
        taxCode: taxCode,
        taxPercentage: taxPercentage,
        created_by: localStorage.getItem("userID"),
      };
      if (rows.length === 0) {
        dataArr.push(data);
      } else {
        dataArr = rows;
        dataArr.push(data);
      }
      updateRows(dataArr);
      setTaxName("");
      setTaxCode("");
      setTaxPercentage("");
      let rowcount = dataArr.length;
      updateTaxCount(rowcount);
    }
  };
  const submitForm = () => {
    if (rows.length === 0) {
      setTableContentError("Please enter tax details");
    } else {
      
      let reqParams = {
        tax_details: rows,
      };

      
      let responseData = submitTaxDetails(reqParams);
      
      Promise.resolve(responseData).then(responseData => {
      
      if (responseData.status ==="200" ) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });
        // let data = {
        //     taxName: '',
        //     taxCode: '',
        //     taxPercentage: '',
        // }
        updatedData(data);
        updateTaxCount(0);
        setTaxName("");
        setTaxCode("");
        setTaxPercentage("");
        // setInputContact('');
        // SetInputAddress('');
        updateRows([]);
        // setDepartureError('');
        // setArrivalError('');
        // setDateError('');
        // setBookingAgentError('');
        // setPassengerCntError('');
        // setTableContentError('');
        // setPaymentError('');
      } else {
        setNotify({
          isOpen: true,
          message: responseData.message,
          type: "error",
        });
      }
    })
    }
  };
  return (
    // DataTable
    <SahoTaxesFormBoot
      handleEditChange={handleEditChange}
      handleEditingDone={handleEditingDone}
      handleCancel={handleCancel}
      handleEditing={handleEditing}
      handleDeleting={handleDeleting}
      addTax={addTax}
      submitForm={submitForm}
      rows={rows}
      taxName={taxName}
      taxCode={taxCode}
      taxPercentage={taxPercentage}
      tableContentError={tableContentError}
      setTaxName={setTaxName}
      setTaxCode={setTaxCode}
      setTaxPercentage={setTaxPercentage}
      editrow={editrow}
      notify={notify}
      setNotify={setNotify}
    />
  );
};

export default SahoTaxes;
