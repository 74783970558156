import React from "react";

import { TextField } from "@mui/material";

import Button from "@mui/material/Button";
import { InputLabel, Grid, Box } from "@mui/material";

export default function SahoVerificationCodeForm({
  state,
  error,
  handleProfileChange,
  submitVerificationCode,
  resendCode,
}) {
  return (
    <div className="login_mainContainer">
      <div className="login_table">
        <Box>
          <h3>Verification Link</h3>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            columns={1}
            spacing={1}
            item
            xs={8}
          >
            <Grid>
              <TextField
                style={{ marginTop: "25px", marginLeft: "25px" }}
                id="outlined-multiline-static"
                placeholder="Verification Code"
                variant="outlined"
                fullwidth
                value={state.verificationcode}
                onChange={handleProfileChange("verificationcode")}
                error={error.verificationcodeErr ? true : false}
                helperText={error.verificationcodeErr}
              />

              <div align="center">
                <Button
                  style={{
                    marginTop: "25px",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  onClick={submitVerificationCode}
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>

          {/* <div>
            <a href="#" onClick={resendCode}>
              Resend the code
            </a>
          </div> */}
        </Box>
      </div>
    </div>
  );
}
