import React, { useState, useEffect } from "react";
import { SAHO_API_URL } from "../config/SahoConstants";
import axios from "axios";
import SahoTransactionForm from "./Forms/SahoTransactionForm";

const SahoTransaction = () => {
  const [rows, updateRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    axios
      .get(SAHO_API_URL + "transaction")
      .then((result) => {
        updateRows(result.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div>
      <SahoTransactionForm
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default SahoTransaction;
