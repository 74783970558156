import React, { useEffect, useState } from "react";
import "./style/SahoNavbar.css";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  Drawer,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import ViewListIcon from "@mui/icons-material/ViewList";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import UserLoginDetails from "../config/user-login-details.json";
import UserLoginDetailsTicketBooking from "../config/user-login-details-ticket-booking.json";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Receipt from "@mui/icons-material/Receipt";
// import { getSubAgents } from "./SahoService";
import { getCredit } from "./SahoService";
import logo from "./style/image/logo.png";
import CreditCardIcon from "@mui/icons-material/CreditCard";


const Navbar = ({ token }) => {
  const history = useHistory();
  /* const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    list: {
      width: theme.spacing(35),
    },
    sahoHeader: {
      marginTop: "0px",
      color: "red",
    },

    listName: {
      marginLeft: theme.spacing(4),
      fontFamily: "Gothic",
    },
    closeMenuButton: {
      position: "absolute",
      right: 0,
    },
    listContainer: {
      marginTop: theme.spacing(1),
    },
    userName: {
      marginLeft: "auto",
      marginLeft: 0,
    },
    logo: {
      marginRight: 0,
      marginRight: "auto",
    },
  }));*/

  const root = {
    flexGrow: 1,
  };
  
  const menuButton = {
    mr: 2,
  };

  let lists= {
    width: 35
  };
  const sahoHeader= {
    mt: 0,
    color: "red",
  };

  const listName= {
    ml: 4,
    fontFamily: "Gothic"
  };

  const closeMenuButton= {
    position: "absolute",
    right: 0
  };

  const listContainer= {
    mt: 1
  };
  
  // const userName = {
  //   ml: "auto",
  //   ml: 0
  // };

  // const logo = {
  //   mr: 0,
  //   mr: "auto"
  // };
  // const [creditAmt, setCreditAmt] = useState("");  
  // useEffect(() => {
  //   let userID = localStorage.getItem("subAgentID");
  //   let userRole = localStorage.getItem("userRole");
  //   console.log(userRole);
  //   let reqParams = {sub_agent_id : userID};  
  //   let resRoleData = getCredit(reqParams);
  //   Promise.resolve(resRoleData).then((result) => {
  //   setCreditAmt(result.data.balance)
  //   });
  // },[creditAmt]);

  const [creditAmt, setCreditAmt] = useState("");  
  var menuItemsList = {};
  let ticketBookingAccess = localStorage.getItem("ticketBookingAccess");
  console.log("ticketBookingAccess",ticketBookingAccess);
  //let ticketBookingAccess = true;

  if(ticketBookingAccess == 'true')
  {
    menuItemsList = UserLoginDetailsTicketBooking;
  }
  else
  {
    menuItemsList = UserLoginDetails;
  }

  console.log("menuItemsList",menuItemsList);
  useEffect(() => {
    let role = localStorage.getItem("userRole");
    if(role === "admin") {
      setCreditAmt("");
    } else {
            let userID = localStorage.getItem("subAgentID");
            let reqParams = {sub_agent_id : userID};  
            let resRoleData = getCredit(reqParams);
            Promise.resolve(resRoleData).then((result) => {
            setCreditAmt(result.data.balance);
            });
          }
        },[creditAmt]);
         
  const userRole = localStorage.getItem("userRole");
  //const classes = useStyles();
  const [state, setState] = useState({
    left: false,
    isAuthenticated: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const logoutHandler = () => {
    console.log("START logoutHandler");
    localStorage.removeItem("userID");
    localStorage.removeItem("userName");
    localStorage.removeItem("userRole");
    localStorage.removeItem("subAgentID");
    localStorage.removeItem("partnerID");
    localStorage.removeItem("ticketBookingAccess");
    window.localStorage.clear();
    history.go("/login");
    console.log("END logoutHandler");
  };
  let menuDetails = [
    {
      name: "Register Sub Agent",
      path: "/sub-agent-registration",
      icon: "HowToRegIcon",
    },
    {
      name: "Create Booking",
      path: "/create-booking",
      icon: "AddToPhotosIcon",
    },
    { name: "Booking List", path: "/booking-list", icon: "ViewListIcon" },
    { name: "Customer List", path: "/customer-list", icon: "PeopleIcon" },
    { name: "New Customer", path: "/new-customer", icon: "PersonAddIcon" },
    { name: "Taxes", path: "/taxes", icon: "AccountBalanceWalletIcon" },
    { name: "Transactions", path: "/transactions", icon: "Receipt" },
    { name: "Sub Agents", path: "/subagentslist", icon: "CreditCardIcon" },
    { name: "Statements", path: "/subagentstatements", icon: "AccountBalanceWalletIcon" },
    { name: "Invoices", path: "/subagentinvoices", icon: "AccountBalanceWalletIcon" },
    { name: "Credit Notes", path: "/subagentcreditnotes", icon: "AccountBalanceWalletIcon" },
    { name: "Update Password", path: "/password-reset", icon: "HowToRegIcon" }


  ];

  const selectedMenu = menuItemsList.map((el) => {
    if (el.userRole === userRole) {
      let menuItem = el.menuItems;

      return menuItem.map((menuObj) => {
        let menuItemName = menuObj.name;
        return menuDetails.map((menuItemObj) => {
          if (menuItemObj.name === menuItemName) {
            return (
              <Link to={menuItemObj.path} color="inherit" className="listLink">
                <ListItem button>
                  {menuItemObj.icon === "HowToRegIcon" && (
                    <HowToRegIcon style={{ color: "#f7a540be" }} />
                  )}
                  {menuItemObj.icon === "AddToPhotosIcon" && (
                    <AddToPhotosIcon style={{ color: "#f7a540be" }} />
                  )}
                  {menuItemObj.icon === "ViewListIcon" && (
                    <ViewListIcon style={{ color: "#f7a540be" }} />
                  )}
                  {menuItemObj.icon === "PeopleIcon" && (
                    <PeopleIcon style={{ color: "#f7a540be" }} />
                  )}
                  {menuItemObj.icon === "PersonAddIcon" && (
                    <PersonAddIcon style={{ color: "#f7a540be" }} />
                  )}
                  {menuItemObj.icon === "CreditCardIcon" && (
                    <CreditCardIcon style={{ color: "#f7a540be" }} />
                  )}
                  {menuItemObj.icon === "Receipt" && (
                    <Receipt style={{ color: "#f7a540be" }} />
                  )}
                  {menuItemObj.icon === "AccountBalanceWalletIcon" && (
                    <AccountBalanceWalletIcon style={{ color: "#f7a540be" }} />
                  )}

                  <Typography color="inherit"   sx={{ listName }}>
                    {menuItemObj.name}
                  </Typography>
                </ListItem>
              </Link>
            );
          }
        });
      });
    }
  });
  const list = (anchor) => (
    <div
      //className={clsx(classes.list)}
      sx={{ lists }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <AppBar position="static" className="navbar_conatiner">
          <Toolbar variant="dense">
            <Typography color="inherit">
              <Link
                to="/"
                color="inherit"
                sx={{ sahoHeader }}
              >
                {/* <h3>
                  Sama <span>Travel</span>
                </h3> */}
                <div className="logo_conatiner">
                  <img src={logo} alt="" />
                </div>
              </Link>
            </Typography>
            <IconButton
              onClick={toggleDrawer("left", false)}
              edge="start"
              sx={{ closeMenuButton }}
              color="inherit"
              aria-label="menu"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          sx={{ listContainer }}
        >
          {token ? (
            <>
              {selectedMenu}
              <Link
                to="/logout"
                color="inherit"
                className="listLink"
                onClick={logoutHandler}
              >
                <ListItem button>
                  <ExitToAppIcon style={{ color: "#f7a540be" }} />
                  <Typography
                    color="inherit"
                    sx={{ listName }}
                  >
                    {"Logout"}
                  </Typography>
                </ListItem>
              </Link>
            </>
          ) : (
            <Link to="/login" color="inherit" className="listLink">
              <ListItem button>
                <AccountCircleIcon />
                <Typography color="inherit"  sx={{ listName }}>
                  {"Login"}
                </Typography>
              </ListItem>
            </Link>
          )}
        </div>
      </List>
    </div>
  );
  var navData;
  console.log("Role_Identifier:",localStorage.getItem("role_identifier"));
  if ((localStorage.getItem("userName") != null) &&(localStorage.getItem("userName") != null) && (localStorage.getItem("userRole") !== "admin"))
  {
    navData = (
      <Typography
        variant="subtitle2"
        color="inherit"
        ml="auto"
        //sx={{userName}}
      >
        <div className="nav_dpName">
          <span>Hi,</span>
          {localStorage.getItem("userName")}
        </div>
        <br/>
        
        {/* <p className="nav_dpcreditamt">
          <span>Credit Balance</span>
          <i className="fas fa-rupee-sign"> {creditAmt} </i>
        </p> */}
      </Typography>
    );
  }
  else if (localStorage.getItem("userName") != null) {
    navData = (
      <Typography
        variant="subtitle2"
        color="inherit"
        ml="auto"
        //sx={{userName}}
      >
        <div className="nav_dpName">
          <span>Hi,</span>
          {localStorage.getItem("userName")}
        </div>
        <br/>        
       
      </Typography>
    );

  }
  return (
    <div sx={{ root }}>
      <AppBar position="static" className=" navbar_conatiner">
        <Toolbar variant="dense">
          <IconButton
            onClick={toggleDrawer("left", true)}
            edge="start"
            sx={{ menuButton }}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography color="inherit">
            <Link
              to="/"
              exact
              color="inherit"
              sx={{ sahoHeader }}
            ></Link>
          </Typography>

          <Typography
            variant="subtitle2"
            color="inherit"
           //sx={{ logo }}
          >
            <div className="logo_conatiner">
              <img src= {logo} alt="" />
            </div>
          </Typography>
          {navData}
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;