import React from "react";
import SahoBookingList from "./SahoBookingList";
import SahoBarChartData from "./SahoBarChartData";
import { Grid, Box } from "@mui/material";

export default function SahoHome() {
  if (localStorage.getItem("userRole") === "admin") {
    return (
      <Box m={2} pt={4}>
        <Grid
          container
          spacing={7}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <SahoBarChartData type="bookings" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SahoBarChartData type="amount" />
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box m={2} pt={3}>
        <Grid
          container
          spacing={5}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <SahoBarChartData type="bookings" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SahoBarChartData type="amount" />
          </Grid>

          <Box m={2} pt={3}>
            <Grid>
              <SahoBookingList page="home"> </SahoBookingList>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  }
}
