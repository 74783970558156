import React, { useState, useEffect } from "react";
import { getCustomers } from "./SahoService";
import SahoCustomersForm from "./Forms/SahoCustomersForm";

const SahoCustomers = () => {
  const [rows, updateRows] = useState([]);
  const [nonFilteredData, setNonFilteredData] = useState([]);

  useEffect(() => {
    let userID = localStorage.getItem("subAgentID");
    console.log(userID);
    let resCustomerData = [];
    if (userID) {
      resCustomerData = getCustomers(userID);
       }
    Promise.resolve(resCustomerData).then((result) => {
      updateRows(result.data);
      setNonFilteredData(result.data);
    });
  }, []);

  const handleQueryChange = (event) => {
    if (event === undefined || event === "") {
      updateRows(nonFilteredData);
    } else {
      let arrNewData = [];
      nonFilteredData.forEach((seriesObj) => {
        if (
          seriesObj.name.toLowerCase().includes(event.toLowerCase()) ||
          seriesObj.address.toLowerCase().includes(event.toLowerCase())
        ) {
          arrNewData.push(seriesObj);
        }
      });
      updateRows(arrNewData);
    }
  };

  const handleCancelSearch = () => {
    updateRows(nonFilteredData);
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "street", headerName: "Street", width: 200 },
    { field: "city", headerName: "City", width: 200 },
    { field: "state", headerName: "State", width: 200 },
    { field: "pincode", headerName: "Pincode", width: 150 },
    { field: "country", headerName: "Country", width: 200 },
    // {
    //   field: "is_agency",
    //   headerName: "Agency",
    //   width: 300,

    //   renderCell: (params) =>
    //     params.row.is_agency === true ? (
    //       <DoneIcon style={{ color: "#008000" }} />
    //     ) : (
    //       <CloseIcon style={{ color: "#FF0000" }} />
    //     ),
    // },
  ];

  return (
    // DataTable
    <SahoCustomersForm
      handleQueryChange={handleQueryChange}
      handleCancelSearch={handleCancelSearch}
      columns={columns}
      rows={rows}
    />
  );
};

export default SahoCustomers;
