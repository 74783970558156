import React from 'react'
import { Alert, Snackbar } from '@mui/material';



/*const useStyles = makeStyles((theme) => ({
    root: {
        top: theme.spacing(9)
    },
}));*/

const root = {
    top: 9
};

export default function SahoNotification(props) {
    const { notify, setNotify } = props;
    //const classes = useStyles();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotify({
            ...notify,
            isOpen: false
        })
    }
    return (
        // DataTable
        <Snackbar sx={{ root }} open={notify.isOpen} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
            <Alert severity={notify.type} onClose={handleClose}>
                {notify.message}
            </Alert>
        </Snackbar>
    )
}
