import axios from "axios";
import { SAHO_API_URL } from "../config/SahoConstants";

let axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};
//Function to submit the Sub Agent Registration
export function submitRegisterSubAgent(data) {
  let responseData = axios
    .post(SAHO_API_URL + "register-sub-agent", data, axiosConfig)
    .then((res) => {
      let response = res.data;

      return response;
    })
    .catch((error) => {
      return error;
    });
  return responseData;
}

//Function for login
export function loginDetails(data) {
  let responseData = axios
    .post(
      SAHO_API_URL + "auth-user",
      { APIParams: { email: data.email, password: data.password } },
      axiosConfig
    )
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      return error;
    });

  return responseData;
}
//Function to submit the updateCredits at booking time
export function updateCredits(credit) {
  let responseData = axios
    .post(
      SAHO_API_URL + `updatecredit/${localStorage.getItem("subAgentID")}`,
      credit,
      axiosConfig
    )
    .then((res) => {
      let response = res.data;
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  return responseData;
}

// Function to Get Credit
export function getCredit(data) {
  let responseData = axios
    .post(SAHO_API_URL + `getcredit`, data, axiosConfig)
    .then((res) => {
      let response = res.data;
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  return responseData;
}

//Function to Add credit
export function AddCredits(data) {
  let responseData = axios
    .post(SAHO_API_URL + `updatecredit`, data, axiosConfig)
    .then((res) => {
      let response = res.data;
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  return responseData;
}

//Function to reset password
export function passwordReset(data) {
  let responseData = axios
    .post(SAHO_API_URL + `password-reset`, data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log({ error });
      return error;
    });
  console.log(responseData);
  return responseData;
}

//Function to update password
export function forgotPassword(data) {
  let responseData = axios
    .post(SAHO_API_URL + `forgot-password`, data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log({ error });
      return error;
    });
  console.log(responseData);
  return responseData;
}

//Function to update subagent status
export function updateStatus(data) {
  let responseData = axios
    .post(SAHO_API_URL + `sub-agent-status`, data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log({ error });
      return error;
    });
  console.log(responseData);
  return responseData;
}

//Function to update ticket booking access
export function ticketBookingAccess(data) {
  let responseData = axios
    .post(SAHO_API_URL + `ticket-booking-access`, data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log({ error });
      return error;
    });
  console.log(responseData);
  return responseData;
}

//Function to generate verification code
export function generateVerificationCode(data) {
  let responseData = axios
    .post(SAHO_API_URL + `generate-verification-code`, data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log({ error });
      return error;
    });
  console.log(responseData);
  return responseData;
}

//Function to submit the verification code
export function passwordVerification(data) {
  let responseData = axios
    .post(SAHO_API_URL + `password-verification`, data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log({ error });
      return error;
    });
  console.log(responseData);
  return responseData;
}

//Function to submit the booking details
export function submitBookingDetails(data) {
  let responseData = axios
    .post(SAHO_API_URL + "create-booking", data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log({ error });
      return error;
    });
  console.log(responseData);
  return responseData;
}

//Function to Retrieve booking list
export function bookingDetails(userID) {
  // let data = [
  //     { bookingAgent: 'Travera Holidays', bookingNumber: 'SAHO0001', arrivalAirport: 'CCJ', departureAirport: 'ATQ', bookingDate: '06-01-2020', passengerCount: 5, bookingStatus: 'Confirmed' },
  //     { bookingAgent: 'Her Holidays', bookingNumber: 'SAHO0002', arrivalAirport: 'MAA', departureAirport: 'TRV', bookingDate: '13-01-2020', passengerCount: 3, bookingStatus: 'Cancelled' },
  //     { bookingAgent: 'ABC Travels', bookingNumber: 'SAHO0003', arrivalAirport: 'BOM', departureAirport: 'COK	', bookingDate: '19-01-2020', passengerCount: 1, bookingStatus: 'Draft' },
  //     { bookingAgent: 'Cox and Kings', bookingNumber: 'SAHO0004', arrivalAirport: 'COK', departureAirport: 'DEL', bookingDate: '21-01-2020', passengerCount: 6, bookingStatus: 'Cancelled' },
  //     { bookingAgent: 'Thomas Cook', bookingNumber: 'SAHO0005', arrivalAirport: 'GOI', departureAirport: 'CCJ', bookingDate: '23-01-2020', passengerCount: 4, bookingStatus: 'Confirmed' },
  //     { bookingAgent: 'SOTC', bookingNumber: 'SAHO0006', arrivalAirport: 'DEL', departureAirport: 'MAA', bookingDate: '25-01-2020', passengerCount: 1, bookingStatus: 'Confirmed' },
  //     { bookingAgent: 'Club Mahindra Holidays', bookingNumber: 'SAHO0007', arrivalAirport: 'BLR', departureAirport: 'TRV', bookingDate: '26-01-2020', passengerCount: 8, bookingStatus: 'Draft' },
  //     { bookingAgent: 'Expedia', bookingNumber: 'SAHO0008', arrivalAirport: 'CCU', departureAirport: 'COK', bookingDate: '26-01-2020', passengerCount: 2, bookingStatus: 'Confirmed' },
  //     { bookingAgent: 'Yatra', bookingNumber: 'SAHO0009', arrivalAirport: 'HYD', departureAirport: 'CCJ', bookingDate: '29-01-2020', passengerCount: 4, bookingStatus: 'Confirmed' },
  //     { bookingAgent: 'XYZ', bookingNumber: 'SAHO0010', arrivalAirport: 'CCU', departureAirport: 'COK', bookingDate: '26-01-2020', passengerCount: 2, bookingStatus: 'Cancelled' },
  //     { bookingAgent: 'Yatra Tours', bookingNumber: 'SAHO0011', arrivalAirport: 'HYD', departureAirport: 'CCJ', bookingDate: '29-01-2020', passengerCount: 4, bookingStatus: 'Confirmed' },
  // ];
  // return data;

  let responseData = axios
    .post(SAHO_API_URL + "booking-list", { id: userID }, axiosConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });

  return responseData;
}

//Function to Retrieve booking list for last 7 days
export function bookingDetailsWeek(userID) {
  let responseData = axios
    .post(SAHO_API_URL + "booking-list-week", { id: userID }, axiosConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });

  return responseData;
}

//Function to Retrieve booking data for last 7 days
export function bookingData(userID) {
  let responseData = axios
    .post(SAHO_API_URL + "booking-data", { id: userID }, axiosConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });

  return responseData;
}

//Function to Retrieve booking data for last 7 days
export function bookingDataAdmin() {
  let responseData = axios
    .post(SAHO_API_URL + "booking-data-admin", axiosConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });

  return responseData;
}
//Function to retrieve airports list
export function getAirports() {
  let responseData = axios
    .get(SAHO_API_URL + "airport-list", axiosConfig)

    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
  return responseData;
}

//Function to retrieve sub agent list
export function getSubAgents() {
  let responseData = axios
    .get(SAHO_API_URL + "sub-agents", axiosConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  return responseData;
}

//Function to Retrieve customers list
export function getCustomers(userID) {
  let responseData = axios
    .post(SAHO_API_URL + "get-customers", { userID }, axiosConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  return responseData;
}

//Function for adding Customers
export function addCustomer(data) {
  let responseData = axios
    .post(SAHO_API_URL + "add-customer", data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  console.log(responseData);

  return responseData;
}

//Function to generate Statement
// export function getStatement(data) {
//   let responseData = axios
//     .get(SAHO_API_URL + "sama-erp-api", data, axiosConfig)
//     .then((res) => {
//       let response = res.data;
//       return response;
//     })
//     .catch((error) => {
//       console.log("error", error);
//       return error;
//     });
//   console.log(responseData);

//   return responseData;
// }

//Function to get Invoices
export function getInvoices(data) {
  let responseData = axios
    .post(SAHO_API_URL + "sama-erp-api", data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  console.log(responseData);

  return responseData;
}

//Function to get all statements

export function viewStatementData(data) {
  let responseData = axios
    .post(SAHO_API_URL + "view-statement", data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  console.log(responseData);

  return responseData;
}

//Function to generate Statement

export function generateStatementData(data) {
  let responseData = axios
    .post(SAHO_API_URL + "sama-erp-api", data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  console.log(responseData);

  return responseData;
}

//Function to generate Invoice

export function generateInvoiceData(data) {
  let responseData = axios.post(SAHO_API_URL + "sama-erp-api", data, {
    responseType: "blob",
    "Access-Control-Allow-Origin": "*",
  });

  console.log(responseData);

  return responseData;
}
//Function to generate Invoice
export function downloadStatementData(data) {
  let responseData = axios.post(SAHO_API_URL + "download-statement", data, {
    responseType: "blob",
    "Access-Control-Allow-Origin": "*",
  });

  console.log(responseData);

  return responseData;
}

//Function for get App Settings
export function getAppSettings(data) {
  let responseData = axios.post(
    SAHO_API_URL + "get-appsettings",
    data,
    axiosConfig
  );
  // .then((res) => {
  //   let response = res.data;
  //   return response;
  // })
  // .catch((error) => {
  //   console.log("error", error);
  //   return error;
  // });
  console.log(responseData);

  return responseData;
}

//Function to Retrieve user roles
export function getUserRoles() {
  let responseData = axios
    .get(SAHO_API_URL + "user-roles", axiosConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  return responseData;
}

//Function to submit Tax details
export function submitTaxDetails(data) {
  let responseData = axios
    .post(SAHO_API_URL + "taxes", data, axiosConfig)
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  return responseData;
}

//Function to get customer values

export function getCustomerValues(subAgentID) {
  let responseData = axios
    .get(
      SAHO_API_URL + "get-customerselect",
      {
        params: {
          id: subAgentID,
        },
      },
      axiosConfig
    )
    .then((res) => {
      let response = res.data;
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  return responseData;
}

//Function to Retrieve Passenger corresponding to customer_id
export function getPassengers(customer_id) {
  let responseData = axios
    .get(
      SAHO_API_URL + "get-passengers",
      {
        params: {
          id: customer_id,
        },
      },
      axiosConfig
    )
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
  return responseData;
}
