import React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  TableSortLabel
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import SortIcon from "@mui/icons-material/Sort";

const pagination = { ml: 4 / 5 };
const StyledTableCell = {
  head: {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.658)",
    pt: 2,
    pb: 2,
    fontFamily: "Gothic",
  },
  body: {
    fontFamily: "Gothic",
    fontSize: 14,
  },
};
const StyledTableRow = {
  root: {
    "&:nth-of-type(odd)": {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor:  "#f3f3f3"
    },
  },
};
const StyledTableSortLabel = {
  icon: {
    color: "	#FFFFFF",
    "& path": {
      fill: "#eee"
    }
  }
};
// css in style/SahocustomerForm.css
export default function SahoBookingListForm({
  handleChangePage,
  handleChangeSearch,
  handleCancelSearch,
  handleSorting,
  page,
  rowsPerPage,
  rows,
  sortBookingAgent,
  sortBookingNumber,
  sortDepartureAirport,
  sortArrivalAirport,
  sortBookingDate,
  sortPassengerCount,
  sortbookingStatus,
  handleChangeRowsPerPage,
}) {
  //const classes = useStyles();
  
  return (
    <div className="root_booklist">
      <Paper elevation={6} className="paperContainer">
       
        <h3 className="Bookinglist_heading">Booking Ticket List</h3>
        {/* <SearchBar
          className="searchbar_bookingList"
          onChange={handleChangeSearch}
          onCancelSearch={handleCancelSearch}
        /> */}

        <TableContainer component={Paper}>
          <Table
            /*className={classes.table}*/
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell sx={{StyledTableCell}}>
                  Booking Agent
                  {/* Passengers Name */}
                 {/* <TableSortLabel sx={{ StyledTableSortLabel}}
                    active
                    direction={sortBookingAgent}
                    IconComponent={SortIcon}
                    onClick={() => handleSorting("bookingAgent")}
                  /> }
                </TableCell> */}
                <TableCell sx={{StyledTableCell}} align="left">
                  Booking No.
                 <TableSortLabel sx={{ StyledTableSortLabel}}
                    active
                    direction={sortBookingNumber}
                    IconComponent={SortIcon}
                    onClick={() => handleSorting("bookingNumber")}
                  />
                </TableCell >
                <TableCell sx={{StyledTableCell}} align="left">
                  Departure
                 <TableSortLabel sx={{ StyledTableSortLabel}}
                    active
                    direction={sortDepartureAirport}
                    IconComponent={SortIcon}
                    onClick={() => handleSorting("departureAirport")}
                  />
                </TableCell >
                <TableCell sx={{StyledTableCell}} align="left">
                  Arrival
                 <TableSortLabel sx={{ StyledTableSortLabel}}
                    active
                    direction={sortArrivalAirport}
                    IconComponent={SortIcon}
                    onClick={() => handleSorting("arrivalAirport")}
                  />
                </TableCell >
                <TableCell sx={{StyledTableCell}} align="left">
                  Travel Date
                 <TableSortLabel sx={{ StyledTableSortLabel}}
                    active
                    direction={sortBookingDate}
                    IconComponent={SortIcon}
                    onClick={() => handleSorting("bookingDate")}
                  />
                </TableCell >
                <TableCell sx={{StyledTableCell}} align="center">
                  No:of Passengers
                 <TableSortLabel sx={{ StyledTableSortLabel}}
                    active
                    direction={sortPassengerCount}
                    IconComponent={SortIcon}
                    onClick={() => handleSorting("passengerCount")}
                  />
                </TableCell >
                <TableCell sx={{StyledTableCell}} align="center">
                  Ticket Price
                 <TableSortLabel sx={{ StyledTableSortLabel}}
                    active
                    direction={sortPassengerCount}
                    IconComponent={SortIcon}
                    onClick={() => handleSorting("ticket_price")}
                  />
                </TableCell >
                <TableCell sx={{StyledTableCell}} align="center">
                  Booking Status
                 <TableSortLabel sx={{ StyledTableSortLabel}}
                    active
                    direction={sortbookingStatus}
                    IconComponent={SortIcon}
                    onClick={() => handleSorting("bookingStatus")}
                  />
                </TableCell >
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow sx={{StyledTableRow}} key={row.id}>
                    {/* <TableCell sx={{StyledTableCell}} component="th" scope="row">
                      {row.sub_agent_name}
                    </TableCell > */}
                    <TableCell sx={{StyledTableCell}} align="left">
                      {row.booking_no}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="left">
                      {row.departure_airport_name}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="left">
                      {row.arrival_airport_name}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="left">
                      {row.travel_date.toString()}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="center">
                      {row.no_of_passengers}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="center">
                      {row.ticket_price}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="center">
                      {(() => {
                        if (row.status === "confirmed") {
                          return <DoneIcon style={{ color: "#008000" }} />;
                        } else if (row.status === "cancelled") {
                          return <CloseIcon style={{ color: "#FF0000" }} />;
                        } else if (row.status === "draft") {
                          return <CreateIcon style={{ color: "#3f51b5" }} />;
                        }
                      })()}
                    </TableCell >
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ pagination }}
          labelRowsPerPage=""
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // onChangePage={()=>handleChangePage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
