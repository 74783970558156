import React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  Button,
} from "@mui/material";

const pagination = { ml: 4 / 5 };
const StyledTableCell = {
  head: {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.658)",
    pt: 2,
    pb: 2,
    fontFamily: "Gothic",
  },
  body: {
    fontFamily: "Gothic",
    fontSize: 14,
  },
};
const StyledTableRow = {
  root: {
    "&:nth-of-type(odd)": {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor: "#f3f3f3",
    },
  },
};
const StyledTableSortLabel = {
  icon: {
    color: "	#FFFFFF",
    "& path": {
      fill: "#eee",
    },
  },
};

export default function SahoStatementsListForm({
  handleChangePage,
  page,
  rowsPerPage,
  rows,
  handleChangeRowsPerPage,
  downloadStatement
}) {
  return (
    <div className="root_booklist">
      <Paper elevation={6} className="paperContainer">
        <h3 className="Bookinglist_heading">Generated Statements</h3>

        <TableContainer component={Paper}>
          <Table size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Start Date
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  End Date
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Payment/Receipt Date From
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Payment/Receipt Date To 
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="center">
                  Generated Date
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="center">
                  Download Link
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow sx={{ StyledTableRow }} key={row.id}>
                    <TableCell sx={{ StyledTableCell }} align="left">                     
                      {new Date(row.start_date).toLocaleDateString('en-IN')}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">                     
                      {new Date(row.end_date).toLocaleDateString('en-IN')}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">                      
                      {new Date(row.payment_date_from).toLocaleDateString('en-IN')}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">                     
                      {new Date(row.payment_date_to).toLocaleDateString('en-IN')}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="center">
                      {new Date(row.generated_date).toLocaleString('en-IN')}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="center">
                      
                      <Button
                            variant="contained"
                            onClick={() => {
                              downloadStatement(row.id);
                            }}
                          >
                            Download
                          </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ pagination }}
          labelRowsPerPage=""
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}