import React, { useState, useEffect } from "react";
import { submitBookingDetails, updateCredits } from "./SahoService";
import { useSnackbar } from "notistack";
import {
  getAirports,
  getSubAgents,
  getCustomerValues,
  getPassengers,
  getCredit

} from "./SahoService";
import { SahoCreateBookingForm } from "./Forms/SahoCreateBookingForm";
import { useHistory } from "react-router-dom";
//import airportData from "../config/airports.json";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SahoCreateBooking = () => {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState({
    selectOptions: [],
    id: "",
    name: "",
  });
  const [passengerValue, setPassengerValue] = useState({
    selectOptions: [],
    id: "",
    name: "",
  });
  const [passengerCount, updatePassengerCount] = useState(0);
  const [inputName, setInputName] = useState("");
  const [inputAge, setInputAge] = useState("");
  const [inputPassport, setInputPassport] = useState("");
  const [inputCity, setInputCity] = useState("");
  const [inputState, setInputState] = useState("");
  const [inputPostal, setInputPostal] = useState("");
  const [inputCountry, setInputCountry] = useState("");
  const [inputContact, setInputContact] = useState("");
  const [inputAddress, SetInputAddress] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [rows, updateRows] = useState([]);
  const [editrow, setEditRow] = useState({});
  const [editIndex, setEditIndex] = useState("");
  const [currentDate, setCurrentDate] = useState(
     new Date().toLocaleDateString()
   );
  const [departureError, setDepartureError] = useState("");
  const [arrivalError, setArrivalError] = useState("");
  const [customerError, setCustomerError] = useState("");
  const [dateError, setDateError] = useState("");
  const [bookingAgentError, setBookingAgentError] = useState("");
  const [passengerCntError, setPassengerCntError] = useState("");
  const [tableContentError, setTableContentError] = useState("");
  const [airportNameList, setAirportNameList] = useState();
  const [subAgentList, setSubAgentList] = useState();
  const [paymentMode, setPaymentList] = useState();
  const [paymentModeError, setPaymentError] = useState();
  //const [submitFlag, setSubmitFlag] = useState(false);
  //const [nameError, setNameError] = useState("");
  const [tcktPrice, setTcktPrice] = useState(10000);
  const [creditLimit, setCreditLimit] = useState("");
  const [updatecreditLimit, setupdateCreditLimit] = useState("");
  const [updatecreditLimitErr, setupdateCreditLimitErr] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, updatedData] = useState({
    arrivalAirportCode: "",
    departureAirportCode: "",
    arrivalAirportName: "",
    departureAirportName: "",
    travelDate: "",
    bookingAgent: "",
    ticket_price: tcktPrice,
    selectedCustomer: "",

    // paymentMode: "",
  });

  const [inputValue, setValue] = useState("1");

  const [passengerDetails, setPassengerDetails] = useState([{}]);

  useEffect(() => {
    let resAirportData = getAirports();
    Promise.resolve(resAirportData).then((result) => {
      setAirportNameList(result.data);
    });

    let resSubAgentData = getSubAgents();
    Promise.resolve(resSubAgentData).then((result) => {
      setSubAgentList(result.data);
    });

    let paymentList = ["Cash", "Card"];
    setPaymentList(paymentList);

    let custValues = getCustomerValues(localStorage.getItem("subAgentID"));
    console.log(localStorage.getItem("subAgentID"));
    console.log(custValues);

    Promise.resolve(custValues).then((result) => {
      const custOptions = result.data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      setSelectedValue({ selectOptions: custOptions });
      //setSelectedValue(custOptions);
    });
  }, []);

    useEffect(() => {
      let userID = localStorage.getItem("subAgentID");
      let reqParams = {sub_agent_id : userID};  
      let resRoleData = getCredit(reqParams);
      Promise.resolve(resRoleData).then((result) => {
       console.log(result.data);
       setCreditLimit(result.data.balance)
      });
    }, [creditLimit]);

  // useEffect(() => {
  //   let userID = localStorage.getItem("subAgentID");

  //   let resRoleData = getSubAgents();
  //   Promise.resolve(resRoleData).then((result) => {
  //     result.data.forEach((obj) => {
  //       if (obj.id == userID) {
  //         setCreditLimit(obj.sub_agent_credit);
  //       }
  //     });
  //   });
  // }, [creditLimit]);
  useEffect(() => {
    //To get passenger details of customers
    let customer_id = inputValue.id;

    if (customer_id != undefined && typeof customer_id == "number") {
      let passengerDetails = getPassengers(customer_id);

      Promise.resolve(passengerDetails).then((result) => {
        const passOptions = result.data.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        setPassengerValue({ selectOptions: passOptions });

        setPassengerDetails(result.data);
      });
    }
  }, [inputValue]);

  const updateCredit = async () => {
    let reqParams = {
      sub_agent_credit: updatecreditLimit,
    };
    let responseData = updateCredits(reqParams);

    Promise.resolve(responseData).then((res) => {});
  };

  //var cust_id;
  let submitFlag = false;
  const submitForm = () => {
    console.log("Inside submit form");
    console.log("departureairportcode");
    console.log(data.departureAirportCode);
    console.log("arrivalairportcode");
    console.log(data.arrivalAirportCode);
    console.log("departureairportname");
    console.log(data.departureAirportName);
    console.log("arrivalairportcodename");
    console.log(data.arrivalAirportName);
    console.log("traveldate");
    console.log(data.travelDate);
    console.log("customer");
    console.log(data.selectedCustomer);
    if (creditLimit < tcktPrice) {
      console.log(creditLimit);
      console.log(tcktPrice);
      setupdateCreditLimitErr("Credit Balance Is Low");
    } else if (data.departureAirport === "") {
      setDepartureError("Please Select Departure Airport");
    } else if (data.arrivalAirport === "") {
      setArrivalError("Please Select Arrival Airport");
    } else if (data.arrivalAirportCode === data.departureAirportCode) {
      console.log("airports are null");
      setArrivalError("Departure And Arrival Airport Cannot Be The Same");
    } else if (data.travelDate === "") {
      setDateError("Please Enter A Valid Date");
    } else if (data.selectedCustomer === "") {
      setCustomerError("Please Enter A Customer");
      // } else if (data.bookingAgent === "") {
      //   setBookingAgentError("Please Enter The Booking Agent Name");
    } else if (rows.length === 0) {
      setTableContentError("Please Enter Passenger Details");
    } else {
      // var customerResponseData = null;
      //var responseData;
      //setupdateCreditLimit(parseInt(creditLimit) - parseInt(tcktPrice));

      // if (typeof inputValue.id !== "number") {
      //   let custParams = {
      //     name: inputValue.name,
      //     sub_agent_id: localStorage.getItem("subAgentID"),
      //     address: "",
      //     is_agency: false,
      //     created_by: localStorage.getItem("userID"),
      //   };

      // var customerResponseData = addCustomer(custParams);

      // if (customerResponseData.status === 1) {
      //   setNotify({
      //     isOpen: true,
      //     message: customerResponseData.message,
      //     type: "success",
      //   });
      // } else {
      //   setNotify({
      //     isOpen: true,
      //     message: customerResponseData.message,
      //     type: "error",
      //   });
      // }
      // } else {
      //   setSubmitFlag(true);
      //   cust_id = inputValue.id;
      // }

      // if (customerResponseData) {
      //   Promise.resolve(customerResponseData).then((result) => {
      //     if (result.data) {
      //       if (result.data.length >= 1) {
      //         cust_id = result.data[0].id;
      //         setNotify({
      //           isOpen: true,
      //           message: "Added Successfully",
      //           type: "success",
      //         });
      //         setSubmitFlag(true);
      //       } else {
      //         setNotify({
      //           isOpen: true,
      //           message: "Failed to add New Customer",
      //           type: "error",
      //         });
      //       }
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: "Network Error",
      //         type: "error",
      //       });
      //     }
      //     if (cust_id != undefined) {
      //       let reqParams = retrieveReqParams();
      //       responseData = submitBookingDetails(reqParams);
      //     }
      //   });
      // }
      submitFlag = true;
      
      console.log("Submit Flag");
      console.log(submitFlag);

      if (submitFlag) {
        console.log("Inside submitflag fn()");
        let reqParams = retrieveReqParams();
        console.log("Booking details:");
        console.log(reqParams);
        let responseData = submitBookingDetails(reqParams);
        //updateCredit();
        Promise.resolve(responseData).then((responseData) => {
          if (responseData.status == "1") {


            enqueueSnackbar("Booking created successfully",  {
              variant: 'success',  preventDuplicate: true
              });


            // setNotify({
            //   isOpen: true,
            //   message: "Added Successfully",
            //   type: "success",
            // });
            let data = {
              arrivalAirportCode: "",
              departureAirportCode: "",
              arrivalAirportName: "",
              departureAirportName: "",
              travelDate: "",
              bookingAgent: "",
              ticket_price: "",
              selectedCustomer: "",
            };
            updatedData(data);
            updatePassengerCount(0);
            setInputName("");
            setInputAge("");
            setInputPassport("");
            setInputContact("");
            SetInputAddress("");
            updateRows([]);
            setDepartureError("");
            setArrivalError("");
            setDateError("");
            setBookingAgentError("");
            setPassengerCntError("");
            setTableContentError("");
            setupdateCreditLimitErr("");
            setPaymentError("");
            setInputContact("");
            setInputCountry("");
            setInputPostal("");
            setInputState("");
            setInputCity("");
            setAirportNameList("");
           //setSelectedValue("");
         

           history.push("/booking-list");
          } else {
            // setNotify({
            //   isOpen: true,
            //   message: responseData.message,
            //   type: "error",
            // });


            enqueueSnackbar("Failed to create the booking",  {
              variant: 'error',  preventDuplicate: true
              });
          }
        });
      }
    }
  };

  const retrieveReqParams = () => {
    console.log("retrieveReqParams");
    var reqParams = {
      arrival_airport_code: data.arrivalAirportCode,
      departure_airport_code: data.departureAirportCode,
      arrival_airport_name: data.arrivalAirportName,
      departure_airport_name: data.departureAirportName,
      travel_date: data.travelDate,
      booking_agent_id: localStorage.getItem("subAgentID"),
      ticket_price: data.ticket_price,
      passenger_count: passengerCount,
      passenger_details: rows,
      // mode_of_payment: data.paymentMode,

      amt_without_tax: 0,
      amt_tax: 0,
      amt_total: 15000,
      amt_overhead: 0,
      created_by: localStorage.getItem("userID"),
      customer_id: data.selectedCustomer,
    };
    console.log(reqParams);
    return reqParams;
  };
  // Function to get the no:of Passengers according to row count of the table
  const handleChangeCount = (e) => {
    let count = e.target.value;
    if (count === "" || count === undefined || count === "1") {
      count = 1;
    }
    updatePassengerCount(count);
  };

  // const handleChangeCountFn= handle

  // Function to make the selected row of the table editable
  const handleEditing = (row) => {
    setEditRow(row);
    let index = rows.findIndex((x) => x.name === row.name);
    setEditIndex(index);
  };

  //Function for deleting the selected row of the table
  const handleDeleting = (row) => {
    let orginalRows = rows;
    let passengerName = row.name;
    let newPassengerArray = orginalRows.filter(
      (passenger) => passenger.name !== passengerName
    );
    let newPassengerArrayCount = newPassengerArray.length;
    updateRows(newPassengerArray);
    updatePassengerCount(newPassengerArrayCount);
  };

  //Function to save the changes done by editing
  const handleEditChange = (prop) => (event) => {
    setEditRow({ ...editrow, [prop]: event.target.value });
  };

  const handleBlur = (prop) => (event, value) => {
    // if (prop === "bookingAgent") {
    //   let agentName = event.target.value;

    //   if (agentName === "") {
    //     setBookingAgentError("Please Enter Booking Agent Name");
    //   } else {
    //     setBookingAgentError("");
    //   }
    // }
    // else if (prop === 'passengerCountValue') {
    //     let passengerCnt = event.target.value;
    //     //console.log(passengerCnt)
    //     if (passengerCnt === '' || (passengerCnt <= 0)) {
    //         setPassengerCntError('Please enter no.of passengers travelling');
    //     }
    //     else {
    //         setPassengerCntError('');
    //     }
    // }
    if (prop === "departureAirport") {
      let departureAirport = event.target.value;
      if (departureAirport === "") {
        setDepartureError("Please Select Departure Airport");
      } else {
        setDepartureError("");
      }
    } else if (prop === "arrivalAirport") {
      let arrivalAirport = event.target.value;
      if (arrivalAirport === "" || arrivalAirport === undefined) {
        setArrivalError("Please Select Arrival Airport");
      } else if (arrivalAirport === data.departureAirport) {
        console.log(arrivalAirport);
        console.log(data.departureAirport);
        setArrivalError("Departure And Arrival Airport Cannot Be The Same");
      } else {
        setArrivalError("");
      }
    } else if (prop === "selectedCustomer") {
      let selectedCustomer = event.target.value;
      if (selectedCustomer === "" || selectedCustomer === undefined) {
        setCustomerError("Please Select a Customer");
      } else {
        setCustomerError("");
      }
    }
    if (prop === "travelDate") {
      let travelDate = event.target.value;
      if (!(new Date(travelDate).valueOf() > new Date().valueOf())) {
        setDateError(
          "Date Selected Is Not A Valid Date. Please Select A Date After Todays Date"
        );
      } else if (travelDate === "") {
        setDateError("Please Enter A Valid Date");
      } else {
        setDateError("");
      }
    }
  };

  const handleBookingDetailsChange = (prop) => (event, value) => {
    if (prop === "travelDate") {
      let travelDate = event.target.value;
      if (!(new Date(travelDate).valueOf() > new Date().valueOf())) {
        setDateError(
          "Date Selected Is Not A Valid Date. Please Select A Date After Todays Date"
        );
      } else if (travelDate === "") {
        setDateError("Please Enter A Valid Date");
      } else {
        setDateError("");
        updatedData({ ...data, [prop]: event.target.value });
      }
    } else if (prop === "departureAirport") {
      let departureAirportCode;
      let departureAirportName;
      if (value) {
        console.log(value);
        console.log("departure airport code");
        departureAirportCode = value.code;
        console.log( departureAirportCode);
        console.log("departure airport name");
        departureAirportName = value.name;
        console.log( departureAirportName);
      }
      if (departureAirportCode === "") {
        setDepartureError("Please Select Departure Airport");
      } else {
        setDepartureError("");
        updatedData({ ...data, departureAirportCode: departureAirportCode, departureAirportName: departureAirportName });
       
        console.log("Updated data");
        console.log(data);
      }
    } else if (prop === "arrivalAirport") {
      let arrivalAirportCode;
      let arrivalAirportName;

      if (value) {
        console.log(value);
        arrivalAirportCode = value.code;
        arrivalAirportName = value.name;        
        console.log("arrival airport code");
        console.log(arrivalAirportCode);
        console.log("arrival airport name");
        console.log(arrivalAirportName);
       
      }
      if (arrivalAirportCode === "") {
        setArrivalError("Please Select Arrival Airport");
      } else {
        setArrivalError("");
        updatedData({ ...data, arrivalAirportCode: arrivalAirportCode,  arrivalAirportName: arrivalAirportName });
        
        console.log("Updated data");
        console.log(data);
      }
    } else if (prop === "selectedCustomer") {
      let selectedCustomer;
      if (value) {
        selectedCustomer = value.value;
        setValue({ id: value.value, name: value.label });
      }
      if (selectedCustomer === "") {
        setCustomerError("Please Select a Customer");
      } else {
        setCustomerError("");
        updatedData({ ...data, [prop]: selectedCustomer });
      }
    }

    // else if (prop === "bookingAgent") {
    //   let bookingAgent;
    //   if (value) {
    //     bookingAgent = value.id;
    //   }
    //   if (bookingAgent === "") {
    //     setBookingAgentError("Please Enter Booking Agent Name");
    //   } else {
    //     setBookingAgentError("");
    //     updatedData({ ...data, [prop]: bookingAgent });
    //   }
    // }
    // else if (prop === "paymentMode") {
    //   let payment_mode = value;
    //   if (payment_mode === "") {
    //     setPaymentError("Please Enter The Mode Of Payment");
    //   } else {
    //     setPaymentError("");
    //     updatedData({ ...data, [prop]: payment_mode });
    //   }
    // }
    else {
      updatedData({ ...data, [prop]: event.target.value });
    }
  };

  // Function to Cancel the editing
  const handleCancel = () => {
    setEditRow({});
    setEditIndex("");
  };

  // Function to add the changes done during editing and display the same in the table
  const handleEditingDone = () => {
    let orginalPassengerArray = rows;
    orginalPassengerArray[editIndex] = editrow;

    updateRows(orginalPassengerArray);
    setEditRow({});
    setEditIndex("");
  };

  //Function to handle the changes in Customer select field
  const handleCustomerChange = (e) => {
    setValue({ id: e.value, name: e.label });
  };

  // const handleCreate = (e) => {
  //     console.log("event triggered");
  //    // setValue({id: e.value, name: e.label});
  // }
  // Function to add the passenger details in the table
  const addMorePassengers = () => {
    let dataArr = [];
    //const ageValidation = /^[0-9\b]+$/;
    //const mobileValidation = /^[0]?[789]\d{9}$/;
    //var passportValidation = new RegExp(
    //  "^[A-PR-WYa-pr-wy][1-9]\\d\\s?\\d{4}[1-9]$"
  //  );

    if (inputName === "" || inputName === undefined) {
      setTableContentError("Please Enter The Name Of The Passenger");
    } else if (inputAge === "" || inputAge === undefined) {
      setTableContentError("Please Enter The Age Of The Passenger");
      // } else if (inputPassport === "" || inputPassport === undefined) {
      //   setTableContentError("Please Enter The Passport Number Of The Passenger");
      // } else if (inputContact === "" || inputContact === undefined) {
      //   setTableContentError("Please Enter The Contact Number Of The Passenger");
      // } else if (inputAddress === "" || inputAddress === undefined) {
      //   setTableContentError("Please Enter Ehe Address Of The Passenger");
      // } else if (!ageValidation.test(inputAge)) {
      //   setTableContentError("Please Enter A Valid Age");
      //   } else if (!mobileValidation.test(inputContact)) {
      //     setTableContentError(
      //       "The Contact Number You Entered Is Not Valid. Please Verify Your Contact Number"
      //     );
      //   } else if (!passportValidation.test(inputPassport)) {
      //     setTableContentError(
      //       "The Passport Number You Entered Is Not Valid. Please Verify Your Passport Number"
      //     );
    } else {
      setTableContentError("");
      let data = {
        name: inputName,
        age: inputAge,
        passportNumber: inputPassport,
        contact: inputContact,
        street: inputAddress,
        city: inputCity,
        state: inputState,
        pincode: inputPostal,
        country: inputCountry,
      };
      if (rows.length === 0) {
        dataArr.push(data);
      } else {
        dataArr = rows;
        dataArr.push(data);
      }
      updateRows(dataArr);
      setInputName("");
      setInputAge("");
      setInputPassport("");
      setInputContact("");
      SetInputAddress("");
      setInputContact("");
      setInputCountry("");
      setInputPostal("");
      setInputState("");
      setInputCity("");
      let rowcount = dataArr.length;
      updatePassengerCount(rowcount);
    }
  };
  const handlePassengerNameChange = (e) => {
    setInputName(e.label);
    passengerDetails.map((pd) => {
      if (pd.id === e.value) {
        setInputAge(pd.age);
        SetInputAddress(pd.street);
        setInputCountry(pd.country);
        setInputPostal(pd.pincode);
        setInputState(pd.state);
        setInputCity(pd.city);
        setInputContact(pd.contact_no);
        setInputPassport(pd.passport_no);
      }
    });
  };
  return (
    <>
      {/* DataTable */}
      <SahoCreateBookingForm
        selectedValue={selectedValue}
        airportNameList={airportNameList}
        notify={notify}
        setNotify={setNotify}
        currentDate={currentDate}
        departureError={departureError}
        arrivalError={arrivalError}
        customerError={customerError}
        dateError={dateError}
        bookingAgentError={bookingAgentError}
        tableContentError={tableContentError}
        subAgentList={subAgentList}
        paymentMode={paymentMode}
        paymentModeError={paymentModeError}
        passengerValue={passengerValue}
        handleBookingDetailsChange={handleBookingDetailsChange}
        handleBlur={handleBlur}
        data={data}
        rows={rows}
        inputAge={inputAge}
        setInputAge={setInputAge}
        inputPassport={inputPassport}
        setInputPassport={setInputPassport}
        inputContact={inputContact}
        setInputContact={setInputContact}
        inputAddress={inputAddress}
        SetInputAddress={SetInputAddress}
        addMorePassengers={addMorePassengers}
        submitForm={submitForm}
        handleEditChange={handleEditChange}
        handleCancel={handleCancel}
        handleEditingDone={handleEditingDone}
        handleEditing={handleEditing}
        handleCustomerChange={handleCustomerChange}
        handlePassengerNameChange={handlePassengerNameChange}
        handleDeleting={handleDeleting}
        handleChangeCount={handleChangeCount}
        editrow={editrow}
        inputCity={inputCity}
        setInputCity={setInputCity}
        inputState={inputState}
        setInputState={setInputState}
        inputPostal={inputPostal}
        setInputPostal={setInputPostal}
        inputCountry={inputCountry}
        setInputCountry={setInputCountry}
        updatecreditLimitErr={updatecreditLimitErr}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        style={style}
      />
    </>
  );
};

export default SahoCreateBooking;
