import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
export default function Footer() {
  return (
    <div className="footer-conatiner">
      <h5>
        <CopyrightIcon className="CopyrightIcon" style={{ fontSize: 20 }} />
        <span>Copyright 2021 Sama Travel.</span>
      </h5>
       <p> Agents Helpline +91 484 4193000</p>
    </div>
  );
}
