import React, { useState,useEffect } from "react";
import { forgotPassword } from "./SahoService";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import SahoForgotPasswordForm from "./Forms/SahoForgotPasswordForm.js";
import { useLocation } from "react-router-dom";


const SahoForgotPassword = () => {
  const [state, setState] = useState({
    newpassword: "",
    confirmpassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
  });
  // const [redirect,setredirect] = useState(false);
  const [error, setError] = useState({
    newpasswordErr: "",
    confirmpasswordErr: "",
  });

  const handleClickShowNewPassword = () => {
    setState({ ...state, showNewPassword: !state.showNewPassword });
  };
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setState({ ...state, showConfirmPassword: !state.showConfirmPassword });
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const location = useLocation();
  const  [email, setEmail] = useState("");

  useEffect(() => {
    console.log("email from verification code redirect",location.state.email);
    setEmail(location.state.email);
   }, []);

  // Function to submit the new password
  const history = useHistory();
  const submitForm = () => {
    if (state.newpassword === "") {
      setError({ ...error, newpasswordErr: "Please enter the Password" });
    } else if (state.newpassword === "") {
      setError({ ...error, confirmpasswordErr: "Please confirm the entered Password" });
    } else {
      setError({
        ...error,
        newpasswordErr: "",
        confirmpasswordErr: "",
      });
      let reqParams = {
        email: email,
        new_password: state.newpassword,
        confirm_password: state.confirmpassword,
      };

      console.log("submitForm",reqParams);

      let responseData = forgotPassword(reqParams);

      Promise.resolve(responseData).then((res) => {
        if (res.status == 1) {
          enqueueSnackbar(res.message, {
            variant: "success",
            preventDuplicate: true,
          });
          setTimeout(() => {
            history.push("/login");
          }, "500");
        } else if (res.status == 2) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        } 
        else if (res.status == 3) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
        setState({
          newpassword: "",
          confirmpassword: "",
        });
      });
    }
  };

  const handleProfileChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  return (
    <SahoForgotPasswordForm
      state={state}
      error={error}
      submitForm={submitForm}
      handleProfileChange={handleProfileChange}
      handleClickShowNewPassword={handleClickShowNewPassword}
      handleMouseDownNewPassword={handleMouseDownNewPassword}
      handleClickShowConfirmPassword={handleClickShowConfirmPassword}
      handleMouseDownConfirmPassword={handleMouseDownConfirmPassword}
    />
  );
};

export default SahoForgotPassword;
