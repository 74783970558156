import React from "react";
import { getCredit } from "../SahoService";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  TableCell,
 } from "@mui/material";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
const pagination = { ml: 4 / 5 };

const StyledTableCell = {
  head: {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.658)",
    pt: 2,
    pb: 2,
    fontFamily: "Gothic",
  },
  body: {
    fontFamily: "Gothic",
    fontSize: 14,
  },
};
const StyledTableRow = {
  root: {
    "&:nth-of-type(odd)": {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor: "#f3f3f3",
    },
  },
};
export default function SahoSubAgentDataForm({
  handleChangePage,
  page,
  rowsPerPage,
  credit,
  setId,
  setSub_agent_credit,
  handleOpen,
  handleClose,
  setAccount_number,
  setNarration,
  setIfsc,
  setbranch_name,
  updateCredit,
  style,
  open,
  setAddCredit,
  sub_agent_credit,
  narration,
  id,
  handleChangeRowsPerPage,
  setStatus,
  setTicketBookingAccess
}) {
  //const classes = useStyles();
 
  const history = useHistory();
  


  return (
    <div className="SahoAddCredit_conatiner">
      <Paper elevation={6} className="paperContainer">
        <h3 className="Bookinglist_heading">Sub Agents List</h3>

        <TableContainer component={Paper}>
          <Table
            /* className={classes.table}*/
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ StyledTableCell }}>Sub Agents</TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Email
                </TableCell>               
                <TableCell sx={{ StyledTableCell }} align="left">
                  Credit Balance
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Add Credit
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  View Bookings
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Status
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Ticket Booking Access
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {credit
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((credits) => (
                  <TableRow sx={{ StyledTableRow }} key={credits.id}>
                    <TableCell
                      sx={{ StyledTableCell }}
                      component="th"
                      scope="row"
                    >
                      {credits.name}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">
                      {credits.email}
                    </TableCell>
                    
                    <TableCell sx={{ StyledTableCell }} align="left">
                      {credits.credit_balance}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">
                      <Button
                       variant="contained"
                       
                        onClick={() => {
                          setId(credits.id);
                          setSub_agent_credit(credits.sub_agent_credit);
                          handleOpen();
                        }}
                      >
                        Add credit
                      </Button>
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">
                      <Button
                       variant="contained"
                        onClick={() => {
                          history.push({
                            pathname: '/booking-list',
                            state: { userID : credits.id }
                          })
                        }}
                      >
                        View Bookings
                      </Button>
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">
                   
                      {(() => {
                        if (credits.active) {
                          return <Button variant="contained" onClick={() => {
                           setStatus(credits.id,credits.active,credits.user_id)
                          }}> Disable</Button> ;
                        } else  {
                          return <Button variant="contained" onClick={() => {
                            setStatus(credits.id,credits.active,credits.user_id)
                          }}> Enable</Button> ;
                        }
                      })()}                    

                    
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                   
                      {(() => {
                        if (credits.ticket_booking_access) {
                          return <Button variant="contained" onClick={() => {
                           setTicketBookingAccess(credits.id,credits.ticket_booking_access,credits.user_id)
                          }}> Disable</Button> ;
                        } else  {
                          return <Button variant="contained" onClick={() => {
                            setTicketBookingAccess(credits.id,credits.ticket_booking_access,credits.user_id)
                          }}> Enable</Button> ;
                        }
                      })()}                    

                    
                </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ pagination }}
          labelRowsPerPage=""
          rowsPerPageOptions={[]}
          component="div"
          count={credit.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open} className="box">
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h4"
                component="h2"
                className="modal_bodybooking_head"
              >
                <h4 className="modal_head">Add Credits</h4>
                <IconButton
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon color="secondary" />
                </IconButton>
              </Typography>
              {/* <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                className="modal_body"
              >
                <label htmlFor="" className="modal_bodyac">
                  Account number :
                </label>
                <input
                  type="text"
                  placeholder="Account number"
                  onChange={(e) => setAccount_number(e.target.value)}
                />
              </Typography> */}
              {/* <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                className="modal_body"
              >
                <label htmlFor="" className="modal_bodyifsc">
                  IFSC :
                </label>
                <input
                  type="text"
                  placeholder="IFSC code"
                  onChange={(e) => setIfsc(e.target.value)}
                />
              </Typography> */}
              {/* 
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                className="modal_body"
              >
                <label htmlFor="">Branch name :</label>
                <input
                  type="text"
                  placeholder="Branch name"
                  onChange={(e) => setbranch_name(e.target.value)}
                />
              </Typography>  */}
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                className="modal_body"
              >
                <label htmlFor="" className="modal_bodyamt">
                  Credit Amount :
                </label>
                <input
                  type="number"
                  className="addcr_input"
                  //placeholder="Credit amount"
                  onChange={(e) => setAddCredit(e.target.value)}
                />
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                className="modal_body"
              >
                <label htmlFor="" className="modal_bodyac">
                  Transaction Details :
                </label>
                <input
                  type="text"
                  //placeholder="Transaction Details"
                  onChange={(e) => setNarration(e.target.value)}
                />
              </Typography>
              
             
              {/* <Typography
                id="transition-modal-description"
                className="modal_payment"
              >
                <input
                  type="radio"
                  id="Card"
                  name="payments_mode"
                  value="Card"
                />
                <label for="card">Card</label>
                <input
                  type="radio"
                  id="Cash"
                  name="payments_mode"
                  value="Cash"
                />
                <label for="cash">Cash</label>
              </Typography> */}
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                className="modal_btn"
              >
                <Button
                  onClick={() => {
                    updateCredit(id, sub_agent_credit, narration);
                  }}
                >
                  Add credit
                </Button>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </Paper>
    </div>
  );
}
