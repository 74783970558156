import React, { useState, useEffect } from "react";
import {
  submitRegisterSubAgent,
  getUserRoles,
  getInvoices,
} from "./SahoService";
import { MenuItem } from "@mui/material";
import SahoRegisterSubAgentFormNew from "./Forms/SahoRegisterSubAgentFormNew";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

const SahoRegisterSubAgentNew = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const history = useHistory();

  var chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var passwordLength = 12;
  var password = "";
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }

  const [data, setDataValues] = useState({
    name: "",
    email: "",
    address: "",
    states: "",
    city: "",
    pincode: "",
    country: "",
    subAgentType: "",
    ticketBookingAccess: "",
    partnerID: "",
    password: password,
    accountantCounts: "",
    adminCount: "",
    bookingAgentCount: "",
    usersDetail: [],
    creditLimit: "",
    narration: "",
    gstin: "",
    created_by: localStorage.getItem("userID"),
    companyDetails: []
  });

  const [user, setUser] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoadingPartnerID, setisLoadingPartnerID] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [accountantCntError, setAccountantCntError] = useState("");
  const [adminCntError, setAdminCntError] = useState("");
  const [bookingAgentCntError, setBookingAgentCntError] = useState("");
  const [creditLimitError, setCreditLimitError] = useState("");
  const [gstinError, setGSTINError] = useState("");
  const [subagentTypeError, setSubagentTypeError] = useState("");
  const [ticketBookingAccessError, setticketBookingAccessError] = useState("");
  const [userError, setUserError] = useState("");
  const [userRoleError, setUserRoleError] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [partnerIDError, setpartnerIDError] = useState("");
  const [companyIDError, setcompanyIDError] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [userRoleName, setUSerRoleName] = useState([]);
  const [subAgentDetails, setsubAgentDetails] = useState([{}]);
  const [partnerID, setPartnerID] = useState([]);
  const [companyID, setCompanyID] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(['']); 
  

  useEffect(() => {
    let resRoleData = getUserRoles();
    console.log(resRoleData);
    Promise.resolve(resRoleData).then((result) => {
      let arrRoles = [];
      let roleNameArr = [];
      result.data.forEach((obj) => {
        console.log(obj.role_identifier);

        roleNameArr.push(obj);
        arrRoles.push(<MenuItem value={obj.id}>{obj.name}</MenuItem>);
      });
      console.log(roleNameArr);
      console.log(arrRoles);
      setUSerRoleName(roleNameArr);
      setRoleData(arrRoles);
    });
  }, []);

  //Function to get subagent details from Odoo Erp

  const getSubAgentData = (email) => {
    let params = {
      odoo_model: "cz.api.integration",
      odoo_method: "search_partner_details_with_email_all_company",
      odoo_request_type: "data",
      email: email,
    };
    setisLoadingPartnerID(true);
    let responseData = getInvoices(params);
    console.log("responseData", responseData);
    responseData
      .then((res) => {
        if (res.status === "1") {
          setsubAgentDetails(res.partner_details);
          let partnerIDMenuItems = [];
          partnerIDMenuItems.push(
            <MenuItem value="0">Select Partner</MenuItem>
          );
          res.partner_details.forEach((obj) => {
            partnerIDMenuItems.push(
              <MenuItem value={obj.partner_id}>
                {obj.name + " -- " + obj.partner_id}
              </MenuItem>
            );
          });
          let companyIDMenuItems = [];
          companyIDMenuItems.push(
            <MenuItem key="default" value="">Select Company</MenuItem>
          );
          res.partner_details.forEach((obj) => {
            companyIDMenuItems.push(
              <MenuItem  value={obj.partner_id + "-" + obj.company_id + "-" + obj.company_name} key={obj.partner_id}>
                {obj.company_name + " -- " + obj.name + " -- " + obj.partner_id}
              </MenuItem>
            );
          });
          setCompanyID(companyIDMenuItems);
          setPartnerID(partnerIDMenuItems);        
          setisLoadingPartnerID(false);
          setDataValues({ ...data, ["partnerID"]: "0" });
        } else {
          enqueueSnackbar("Technical error.Could not fetch partner IDs", {
            variant: "error",
            preventDuplicate: true,
          });
        }
        if (res.partner_details.length === 0) {
          setpartnerIDError("Partner IDs not found");
        } else {
          setpartnerIDError("");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //Function to populate user details in an array in Add button click
  const addUserDetails = () => {
    if (user === "") {
      setUserError("Enter Name Of The User");
    } else if (userRole === "") {
      setUserRoleError("Select User Role");
    } else if (userEmail === "") {
      setUserEmailError("Please Enter Email");
    } else {
      setUserError("");
      setUserRoleError("");
      setUserEmailError("");
      let userarr = [];
      console.log(userRoleName);
      let userRoleText =
        userRoleName.find((obj) => obj.id === userRole).name ?? "";
      console.log(userRoleText);
      console.log(userRole);

      //setUserRoleValue(userRoleText);

      let userarrObj = {
        user: user,
        role: userRole,
        roleName: userRoleText,
        email: userEmail,
      };
      if (data.usersDetail.length < 0) {
        userarr.push(userarrObj);
        setDataValues({ ...data, usersDetail: userarr });
      } else {
        userarr = data.usersDetail;
        userarr.push(userarrObj);
        setDataValues({ ...data, usersDetail: userarr });
        setUser("");
        setUserRole("");
        setUserEmail("");
      }
    }
  };

  //Function to submit the sub agent registration form
  const submitForm = () => {
    const dataToSubmit = data;

    var gstinformat = new RegExp(
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
    );
    let accountantCnt = 0;
    let adminCnt = 0;
    let bookingAgentCnt = 0;
    let strGSTIN = data.gstin.toString();
    let dataArr = data.usersDetail;
    dataToSubmit.companyDetails = selectedCompanies;

    dataArr.forEach((dataObj) => {
      if (dataObj.role === "Accountant") {
        accountantCnt = accountantCnt + 1;
      } else if (dataObj.role === "Ticket Booking Agent") {
        bookingAgentCnt = bookingAgentCnt + 1;
      } else if (dataObj.role === "Admin") {
        adminCnt = adminCnt + 1;
      }
    });
    if (data.name === "") {
      setNameError("Please Enter Sub Agent Name");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.email === "") {
      setUsernameError("Please Enter Sub Agent Email");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.city === "") {
      setAddressError("Please Enter Your City");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.states === "") {
      setAddressError("Please Enter Your State");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.address === "") {
      setAddressError("Please Enter Your Street");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.pincode === "") {
      setAddressError("Please Enter Your Postal Code");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.country === "") {
      setAddressError("Please Enter Your Country");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.partnerID === "") {
      setAddressError("Please select Partner ID");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.accountantCounts === "") {
      setAccountantCntError(
        "Maximum Number Of Accountants Field Is Mandatory. Please Enter The Count"
      );
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.adminCount === "") {
      setAdminCntError(
        "Maximum Number Of Admins Field Is Mandatory. Please Enter The Count"
      );
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.bookingAgentCount === "") {
      setBookingAgentCntError(
        "Maximum Number Of Travel Booking Agents Field Is Mandatory. Please Enter The Count"
      );
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.subAgentType === "") {
      setSubagentTypeError(
        "Subagent Type Is Mandatory. Please Select A Subagent Type."
      );
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.ticketBookingAccess === "") {
      setticketBookingAccessError("Please select Ticket Booking Access.");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (data.subAgentType === "organization" && data.gstin === "") {
      setGSTINError(
        "GSTIN Number Required For Organizations. Please Enter The GSTIN Number"
      );
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (accountantCnt > data.accountantCounts) {
      setAccountantCntError(
        "Number Of Accountants Added Exceeds The Maximum Accountants Allowed Limit.Please Change The Maximum Accountants Allowed Limit"
      );
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else if (adminCnt > data.adminCount) {
      setAdminCntError(
        "Number Of Admins Added Exceeds The Maximum Admins Allowed Limit.Please Change The Maximum Admins Allowed Limit"
      );
      setNotify({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    } else if (bookingAgentCnt > data.bookingAgentCount) {
      setBookingAgentCntError(
        "Number of Ticket Booking Agents added exceeds the maximum Ticket Booking Agents allowed limit.Please change the maximum Ticket Booking Agents allowed limit"
      );
      setNotify({
        isOpen: true,
        message: "Please fill the required fields",
        type: "error",
      });
    } else if (
      /*else if ((data.subAgentType === 'organization') && (!((strGSTIN.length === 15) && (strGSTIN.charAt(0)).match('[0-9]') && (strGSTIN.charAt(1)).match('[0-9]') && (strGSTIN.charAt(2)).match('[A-Z]') && (strGSTIN.charAt(3)).match('[A-Z]') && (strGSTIN.charAt(4)).match('[A-Z]') && (strGSTIN.charAt(5)).match('[A-Z]') && (strGSTIN.charAt(6)).match('[A-Z]') && (strGSTIN.charAt(7)).match('[0-9]') && (strGSTIN.charAt(8)).match('[0-9]') && (strGSTIN.charAt(9)).match('[0-9]') && (strGSTIN.charAt(10)).match('[0-9]') && (strGSTIN.charAt(11)).match('[A-Z]') && (strGSTIN.charAt(12)).match('[0-9]') && (strGSTIN.charAt(13)).match('[A-Z]') && (strGSTIN.charAt(14)).match('[0-9]')))) {
            setGSTINError('GSTIN number you entered is invalid. Please enter the correct GSTIN number  ');
            setNotify({
                isOpen: true,
                message: "Please fill the required fields",
                type: 'error',
            })
        }*/
      data.subAgentType === "organization" &&
      gstinformat.test(strGSTIN) === false
    ) {
      setGSTINError(
        "GSTIN Number Entered Is Invalid. Please Enter The Correct GSTIN Number"
      );
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    }
    // else if (dataArr.length === 0) {
    //   setNotify({
    //     isOpen: true,
    //     message: "Please add the user role by clicking + button",
    //     type: "error",
    //   });
    // }
    else if (data.creditLimit == "") {
      setCreditLimitError("Please Enter Credit Limit");
      setNotify({
        isOpen: true,
        message: "Please Fill The Required Fields",
        type: "error",
      });
    } else {
      let responseData = submitRegisterSubAgent(dataToSubmit);

      Promise.resolve(responseData).then((res) => {
        if (res.status == "1") {
          enqueueSnackbar(res.message, {
            variant: "success",
            preventDuplicate: true,
          });
          setSelectedCompanies([]);
          let dataValues = {
            name: "",
            email: "",
            address: "",
            states: "",
            city: "",
            pincode: "",
            country: "",
            partnerID: "",
            subAgentType: "",
            ticketBookingAccess: "",
            accountantCounts: "",
            adminCount: "",
            bookingAgentCount: "",
            usersDetail: [],
            creditLimit: "",
            narration: "",
            gstin: "",
            companyDetails: []
          };
          setDataValues(dataValues);
          console.log("Data Values after reset:");
          console.log(data);
          setNameError("");
          setUsernameError("");
          setAddressError("");
          setSubagentTypeError("");
          setticketBookingAccessError("");
          setAccountantCntError("");
          setAdminCntError("");
          setBookingAgentCntError("");
          setCreditLimitError("");
          setGSTINError("");
          setUserError("");
          setUserRoleError("");
          setUserEmailError("");
          setpartnerIDError("");
          history.push("/subagentslist");
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
    }
  };

  // Function to save the values in a state
  const handleChange = (prop) => (event) => {
    setDataValues({ ...data, [prop]: event.target.value });
  };

  //Function to save company ids selected
  const handleCompanyChange = (event) => {
    const selectedCompanyIds = event.target.value.filter((id) => id !== '');
     setSelectedCompanies(selectedCompanyIds);
    console.log("selected companies",selectedCompanies);
  }
  



  // Function to autofill data based on partner id selected
  const handlePartnerIDChange = (prop) => (event) => {
    const filteredSubAgentDetails = subAgentDetails.filter((data) => {
      return data.partner_id === event.target.value;
    });

    const tmpdata = data;

    setDataValues({
      ...tmpdata,
      ["name"]: filteredSubAgentDetails[0].name,
      ["address"]: filteredSubAgentDetails[0].street,
      [prop]: event.target.value,
      ["city"]: filteredSubAgentDetails[0].city,
      ["states"]: filteredSubAgentDetails[0].state,
      ["country"]: filteredSubAgentDetails[0].country,
      ["pincode"]: filteredSubAgentDetails[0].pin,
      ["gstin"]: filteredSubAgentDetails[0].gstin,
      ["bookingAgentCount"]: "1",
      ["accountantCounts"]: "1",
      ["adminCount"]: "1",
      ["creditLimit"]: "0",
      ["ticketBookingAccess"]: "false",
      ["subAgentType"]: filteredSubAgentDetails[0].gstin
        ? "organization"
        : "individual",
    });
  };

  // Function to handle the errors
  const handleBlur = (prop) => (event) => {
    if (prop === "name") {
      let name = event.target.value;
      if (name === "") {
        setNameError("Please enter Sub Agent name");
      } else if (name.match(/^-?\d+$/)) {
        setNameError("Invalid Name");
      } else {
        setNameError("");
      }
    } else if (prop === "email") {
      let email = event.target.value;
      let emailValidation = new RegExp(
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
      );
      if (email === "") {
        setUsernameError("Please enter Sub Agent email");
      } else if (!emailValidation.test(email)) {
        setUsernameError(
          "The email address you entered is not valid. Please verify your email address"
        );
      } else {
        setUsernameError("");
        getSubAgentData(email);
      }
    } else if (prop === "address") {
      let address = event.target.value;
      if (address === "") {
        setAddressError("Please enter your address");
      } else {
        setAddressError("");
      }
    } else if (prop === "state") {
      let address = event.target.value;
      if (address === "") {
        setAddressError("Please enter your address");
      } else {
        setAddressError("");
      }
    } else if (prop === "city") {
      let address = event.target.value;
      if (address === "") {
        setAddressError("Please enter your address");
      } else {
        setAddressError("");
      }
    } else if (prop === "postal") {
      let address = event.target.value;
      if (address === "") {
        setAddressError("Please enter your address");
      } else {
        setAddressError("");
      }
    } else if (prop === "country") {
      let address = event.target.value;
      if (address === "") {
        setAddressError("Please enter your address");
      } else {
        setAddressError("");
      }
    } else if (prop === "partnerID") {
      let partnerID = event.target.value;
      if (partnerID === "") {
        setpartnerIDError("Please select Partner ID");
      } else {
        setpartnerIDError("");
      }
    } else if (prop === "companyID") {
      let companyID = event.target.value;
      if (companyID === "") {
        setcompanyIDError("Please select Company ID");
      } else {
        setcompanyIDError("");
      }
    } else if (prop === "accountantCounts") {
      let accountantsCnt = event.target.value;
      if (accountantsCnt === "") {
        setAccountantCntError("Please enter maximum number of Accountants");
      } else {
        setAccountantCntError("");
      }
    } else if (prop === "adminCount") {
      let adminCnt = event.target.value;
      if (adminCnt === "") {
        setAdminCntError("Please enter maximum number of Admins");
      } else {
        setAdminCntError("");
      }
    } else if (prop === "bookingAgentCount") {
      let bookingAgentCnt = event.target.value;
      if (bookingAgentCnt === "") {
        setBookingAgentCntError(
          "Please enter maximum number of Ticket Booking Agents"
        );
      } else {
        setBookingAgentCntError("");
      }
    } else if (prop === "subAgentType") {
      let subagentType = event.target.value;
      if (subagentType === "") {
        setSubagentTypeError("Please select Sub Agent type");
      } else {
        setSubagentTypeError("");
      }
    } else if (prop === "ticketBookingAccess") {
      let ticketBookingAccess = event.target.value;
      if (ticketBookingAccess === "") {
        setticketBookingAccessError("Please select Ticket Booking Access");
      } else {
        setticketBookingAccessError("");
      }
    } else if (prop === "userName") {
      let userName = event.target.value;
      if (userName === "") {
        setUserError("Enter name of the user");
      } else {
        setUserError("");
      }
    } else if (prop === "userRole") {
      let userRole = event.target.value;
      if (userRole === "") {
        setUserRoleError("Select User Role");
      } else {
        setUserRoleError("");
      }
    } else if (prop === "userEmail") {
      let email = event.target.value;
      let emailValidation = new RegExp(
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
      );
      if (email === "") {
        setUserEmailError("Please enter email");
      } else if (!emailValidation.test(email)) {
        setUserEmailError(
          "The email address you entered is not valid. Please verify your email address"
        );
      } else {
        setUserEmailError("");
      }
    } else if (prop === "creditLimit") {
      let creditLimit = event.target.value;
      if (creditLimit === "") {
        setCreditLimitError("Please enter the credit limit");
      } else {
        setCreditLimitError("");
      }
    }
  };

  // Function to delete a row in the user data table
  const handleDeleting = (row) => {
    let orginalRows = data.usersDetail;
    let subAgentUser = row.user;
    let newsubAgentUserArray = orginalRows.filter(
      (userObj) => userObj.user !== subAgentUser
    );
    setDataValues({ ...data, usersDetail: newsubAgentUserArray });
  };

  return (
    <SahoRegisterSubAgentFormNew
      addUserDetails={addUserDetails}
      submitForm={submitForm}
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleDeleting={handleDeleting}
      data={data}
      user={user}
      setUser={setUser}
      userRole={userRole}
      setUserRole={setUserRole}
      partnerID={partnerID}
      companyID={companyID}
      handlePartnerIDChange={handlePartnerIDChange} 
      handleCompanyChange={handleCompanyChange}    
      selectedCompanies={selectedCompanies}
      userEmail={userEmail}
      setUserEmail={setUserEmail}
      nameError={nameError}
      usernameError={usernameError}
      creditLimitError={creditLimitError}
      partnerIDError={partnerIDError}      
      addressError={addressError}
      accountantCntError={accountantCntError}
      adminCntError={adminCntError}
      bookingAgentCntError={bookingAgentCntError}
      gstinError={gstinError}
      subagentTypeError={subagentTypeError}
      ticketBookingAccessError={ticketBookingAccessError}
      userError={userError}
      userRoleError={userRoleError}
      userEmailError={userEmailError}
      roleData={roleData}
      notify={notify}
      setNotify={setNotify}
      isLoadingPartnerID={isLoadingPartnerID}
    />
  );
};

export default SahoRegisterSubAgentNew;
