import React, { useState, useEffect } from "react";
import { getInvoices, generateInvoiceData } from "./SahoService";
import SahoInvoicesListForm from "./Forms/SahoInvoicesListForm";
import { useSnackbar } from "notistack";
import download from "downloadjs";
import axios from "axios";

const SahoInvoices = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, updateRows] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [head, setHead] = useState();
  const invoice_data = [];
  const generateInvoice = (record_id, record_no) => {
    const strDateTimeStamp = new Date().toDateString();
    let generateInvoiceValues = {
      record_id: record_id,
      record_no: record_no,
      odoo_report: "account.invoice.ticket",
      odoo_request_type: "report",
    };
    generateInvoiceData(generateInvoiceValues)
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        download(blob, `${record_no}`);
      })
      .catch((err) => {
        enqueueSnackbar("Failed to generate invoice", {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };

  useEffect(() => {    
  let partnerIDs = [];
  const companyDetails =  JSON.parse(localStorage.getItem("companyDetails")); 
  partnerIDs = companyDetails.map(company => company.partnerID);  
    console.log("props_invoice_type:",props.invoice_type);
    if (props.invoice_type == "invoice") {
     var type = "out_invoice";
     setHead("Invoices");
    }
    if (props.invoice_type == "credit_note") {
      var type = "out_refund";
      setHead("Credit Notes");
    }
    let params = {
      odoo_model: "cz.api.integration",
      odoo_method: "get_invoices_of_partner_list",
      odoo_request_type: "data",
      partner_ids: partnerIDs,
    };
    let responseData = getInvoices(params);
    console.log("responseData", responseData);
    responseData
      .then((res) => {
        console.log("API result", res);
        console.log("type", type);
        for (var i = 0; i < res.detail_invoices.length; i++) {
          if (res.detail_invoices[i].type == type) {
            invoice_data.push(res.detail_invoices[i]);
          }
        }
        console.log("invoice_data", invoice_data);
        updateRows(invoice_data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [props.invoice_type]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div>
      <SahoInvoicesListForm
        handleChangePage={handleChangePage}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={rows}
        head={head}
        setRowsPerPage={setRowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        generateInvoice={generateInvoice}
      />
    </div>
  );
};

export default SahoInvoices;