import React, { useState, useEffect } from "react";
import SahoBarChart from "./Forms/SahoBarChart";
import { bookingData, bookingDataAdmin } from "./SahoService";

export default function SahoBarChartData(props) {
  const [bookingValues, setbookingValues] = useState([]);

  var count = [];
  var sum = [];
  var dateArray = [];

  useEffect(() => {
    if (localStorage.getItem("userRole") === "admin") {
      let responseData = bookingDataAdmin();
      Promise.resolve(responseData).then((result) => {
        setbookingValues(result.data);
      });
    } else {
      let responseData = bookingData(localStorage.getItem("subAgentID"));
      Promise.resolve(responseData).then((result) => {
        setbookingValues(result.data);
      });
    }
  }, []);

  bookingValues.forEach((obj) => {
    let newdate = new Date(obj.date);
    dateArray.push(newdate.toLocaleDateString());
    count.push(parseInt(obj.count));
    sum.push(parseInt(obj.sum));
  });

  if (props.type == "bookings") {
    var chartTitle = "Total Number of Bookings for Last 7 days";
    var chartData = count;
  } else if (props.type == "amount") {
    var chartTitle = "Total Amount of Bookings for Last 7 days";
    var chartData = sum;
  }

  console.log(chartData);
  const options = {
    chart: {
      type: "column",
      spacing: 35,
    },
    title: {
      text: chartTitle,

      style: {
        color: "navyblue",
        fontWeight: "bold",
        fontSize: "20px",
      },
    },

    xAxis: {
      type: "category",
      maxPadding: 0.25,
      categories: dateArray,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "navyblue",
          fontWeight: "bold",
        },
      },
    },
    yAxis: {
      maxPadding: 0.25,
      allowDecimals: false,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          fontWeight: "bold",
          color: "navyblue",
        },
      },
    },
    series: [
      {
        showInLegend: false,
        data: chartData,
      },
    ],
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return this.x + ", " + this.y;
      },
    },
  };

  return <SahoBarChart options={options} />;
}
