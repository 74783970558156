import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from "react-router-dom";
import Navbar from "./Components/Navbar";
import SahoHome from "./Components/SahoHome";
import SahoCreateBooking from "./Components/SahoCreateBooking";
import SahoBookingList from "./Components/SahoBookingList";
import SahoLogin from "./Components/SahoLogin";
import SahoRegisterSubAgentNew from "./Components/SahoRegisterSubAgentNew";
import SahoCustomers from "./Components/SahoCustomers";
import SahoAddNewCustomer from "./Components/SahoAddNewCustomer";
import SahoTaxes from "./Components/SahoTaxes";
import Footer from "./Components/Footer";
import SahoTransaction from "./Components/SahoTransaction";
import SahoSubAgentData from "./Components/SahoSubAgentData";
import SahoStatements from "./Components/SahoStatements";
import SahoInvoices from "./Components/SahoInvoices";
import SahoPasswordReset from "./Components/SahoPasswordReset";
import SahoVerificationCode from "./Components/SahoVerificationCode";
import SahoForgotPassword from "./Components/SahoForgotPassword";


const getToken = () => {
  const tokenString = localStorage.getItem("userID");
  const userToken = JSON.parse(tokenString);
  return userToken;
};
const token = getToken();
const userRole = localStorage.getItem("userRole");
function App() {
  const [state, setState] = useState({
    left: false,
    isAuthenticated: false,
  });
  const history = useHistory(); 
  const loginCheck = (data) => {
    console.log('START loginCheck');      
    localStorage.setItem("userID", data.userID);
    localStorage.setItem("userName", data.userName);
    localStorage.setItem("userRole", data.userRole);  
    localStorage.setItem("companyDetails", JSON.stringify(data.companyDetails));
    localStorage.setItem("ticketBookingAccess", data. ticketBookingAccess);   
    setState({ ...state, isAuthenticated: data.userLoggedIn });
    let userRole = localStorage.getItem("userRole");
    if (userRole === "admin") {
      history.go("/sub-agent-registration");
    } else {
      history.go("/booking-list");
    }
    console.log('END loginCheck');
  };


  return (
    <div className="Saho_mainContainer">
        {/* <Header/> */}
        <Navbar token={token} />
        <Switch>
          <Route exact path="/">
            {token ? <SahoHome /> : <Redirect to="/login" />}
          </Route>
          <Route path="/login">
            {token ? (
              <Redirect to="/" />
            ) : (
              <SahoLogin loginCheck={loginCheck} />
            )}
          </Route>
          <Route path="/create-booking">
            {token ? <SahoCreateBooking /> : <Redirect to="/login" />}
          </Route>
          <Route path="/booking-list">
            {token ? <SahoBookingList /> : <Redirect to="/login" />}
          </Route>
          <Route path="/customer-list">
            {token ? <SahoCustomers /> : <Redirect to="/login" />}
          </Route>
          <Route path="/new-customer">
            {token ? <SahoAddNewCustomer /> : <Redirect to="/login" />}
          </Route>
          <Route path="/password-reset">
            {token ? <SahoPasswordReset /> : <Redirect to="/login" />}
          </Route>
          <Route path="/verification-code">
             <SahoVerificationCode /> 
          </Route>
          <Route path="/forgot-password">
             <SahoForgotPassword /> 
          </Route>
          <Route path="/sub-agent-registration">
            {token && userRole === "admin" ? (
              <SahoRegisterSubAgentNew />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route path="/taxes">
            {token ? <SahoTaxes /> : <Redirect to="/login" />}
          </Route>
          <Route path="/transactions">
            {token ? <SahoTransaction /> : <Redirect to="/login" />}
          </Route>
          <Route path="/subagentslist">
            {token ? <SahoSubAgentData /> : <Redirect to="/login" />}
          </Route>
          <Route path="/subagentstatements">
            {token ? <SahoStatements /> : <Redirect to="/login" />}
          </Route>
          <Route path="/subagentinvoices">
            {token ? <SahoInvoices  invoice_type="invoice"/> : <Redirect to="/login" />}
          </Route>
          <Route path="/subagentcreditnotes">
            {token ? <SahoInvoices  invoice_type="credit_note"/> : <Redirect to="/login" />}
          </Route>
        </Switch>
        <Footer />
    </div>
  );
}

export default App;