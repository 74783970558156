import React from "react";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import {
  InputLabel,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
export default function SahoStatementsForm({
  dateValues,
  error,
  submitForm,
  handleCustomerChange,
  handlePaymentDateFrom,
  handlePaymentDateTo,
  creditBalance,
  systemDate,
  isLoadingBalance,
  handlePartnerIDChange,
  selectedPartner,
  companyDetails,
  contactPhone,
  contactEmail
}) {
  return (
    <div className="newCustomer_conatiner">
      <div className="newCustomer_table">
        <div className="spl_notification">
          <Alert severity="info" variant="outlined">
            Note: Finding any discrepancy in your statement, you may kindly
            write to us at {contactEmail}, from your registered e-mail
            ID or call our accounts department at {contactPhone}.
          </Alert>
        </div>
        <div className="newCustomer_addNewCustomer">
          <h5>Account Statements</h5>
          <div className="credit_balance">
            {isLoadingBalance ? (
              <CircularProgress />
            ) : (
              "Total Receivable as on " +
              systemDate +
              " is " +
              creditBalance +
              " ₹"
            )}
          </div>
        </div>
        <div className="newCustomer_dataField">
          <div className="row_add">
            <div className="row_col">
              <InputLabel
                className="register_selectLabel"
                id="demo-simple-select-outlined-label"
                margin="normal"
              >
                <h3
                  style={{
                    fontFamily: "Gothic",
                    textAlign: "left",
                  }}
                >
                  Start Date
                </h3>
              </InputLabel>
              <Input
                label="Date"
                type="date"
                className="textField"
                onChange={handleCustomerChange("start_date")}
                error={error.startDateErr ? true : false}
              />
              <p style={{ color: "red" }}>{error.startDateErr}</p>
            </div>

            <div className="row_col">
              <InputLabel
                className="register_selectLabel"
                id="demo-simple-select-outlined-label"
                margin="normal"
              >
                <h3
                  style={{
                    fontFamily: "Gothic",
                    textAlign: "left",
                  }}
                >
                  End Date
                </h3>
              </InputLabel>
              <Input
                label="Date"
                type="date"
                className="textField"
                //value={dateValues.end_date}
                onChange={handleCustomerChange("end_date")}
                error={error.endDateErr ? true : false}
              />
              <p style={{ color: "red" }}>{error.endDateErr}</p>
            </div>
            <div className="row_col">
              <InputLabel
                className="register_selectLabel"
                id="demo-simple-select-outlined-label"
                margin="normal"
              >
                <h3
                  style={{
                    fontFamily: "Gothic",
                    textAlign: "left",
                  }}
                >
                  Branch
                </h3>
              </InputLabel>
              <Select
                fullWidth
                className="register_select"
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Branch Name"
                labelWidth={120}
                value={selectedPartner}
                onChange={handlePartnerIDChange}
                margin="normal"
              >
                {companyDetails.map((company) => (
                  <MenuItem key={company.companyID} value={company.partnerID}>
                    {company.companyName}
                  </MenuItem>
                ))}
                {selectedPartner}
              </Select>
            </div>
          </div>

          <div className="row_add">
            <div className="row_col">
              <InputLabel
                className="register_selectLabel"
                id="demo-simple-select-outlined-label"
                margin="normal"
              >
                <h3
                  style={{
                    fontFamily: "Gothic",
                    textAlign: "left",
                  }}
                >
                  Payment/Receipt Date From
                </h3>
              </InputLabel>
              <Input
                label="Date"
                type="date"
                id="payment_date_from"
                className="textField"
                value={dateValues.payment_date_from}
                onChange={handlePaymentDateFrom("payment_date_from")}
                error={error.payment_date_fromErr ? true : false}
              />
              <p style={{ color: "red" }}>{error.payment_date_fromErr}</p>
            </div>

            <div className="row_col">
              <InputLabel
                className="register_selectLabel"
                id="demo-simple-select-outlined-label"
                margin="normal"
              >
                <h3
                  style={{
                    fontFamily: "Gothic",
                    textAlign: "left",
                  }}
                >
                  Payment/Receipt Date To
                </h3>
              </InputLabel>
              <Input
                label="Date"
                type="date"
                className="textField"
                value={dateValues.payment_date_to}
                onChange={handlePaymentDateTo("payment_date_to")}
                error={error.payment_date_toErr ? true : false}
              />
              <p style={{ color: "red" }}>{error.payment_date_toErr}</p>
            </div>
            <div className="row_col">
              <h3
                style={{
                  fontFamily: "Gothic",
                  textAlign: "left",
                }}
              ></h3>
            </div>
          </div>

          <div className="newCustomer_Button">
            <Button
              style={{
                marginTop: "25px",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              onClick={submitForm}
              variant="primary"
              size="large"
            >
              Generate Statement
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
