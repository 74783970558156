import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { bookingDetails, bookingDetailsWeek } from "./SahoService";
import SahoBookingListForm from "./Forms/SahoBookingListForm";

function SahoBookingList(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, updateRows] = useState([]);
  const [sortBookingAgent, updateSortingBookingAgent] = useState("asc");
  const [sortBookingNumber, updateSortingBookingNumber] = useState("asc");
  const [sortDepartureAirport, updateSortingDepartureAirport] = useState("asc");
  const [sortArrivalAirport, updateSortingArrivalAirport] = useState("asc");
  const [sortBookingDate, updateSortingBookingDate] = useState("asc");
  const [sortPassengerCount, updateSortingPassengerCount] = useState("asc");
  const [sortbookingStatus, updateSortingBookingStatus] = useState("asc");
  const [data, setData] = useState([]);

  const location = useLocation();
  
  useEffect(() => {
    if (localStorage.getItem("userRole") === "admin") {
      console.log(location.state.userID);
      let responseData = bookingDetails(location.state.userID);    
      Promise.resolve(responseData).then((result) => {
        updateRows(result.data);
        setData(result.data);
      });
    } 
    
    else if (props.page === "home") {
     
      let responseData = bookingDetailsWeek(localStorage.getItem("subAgentID"));

      Promise.resolve(responseData).then((result) => {
        updateRows(result.data);
        setData(result.data);
      });
    }
    else {
      console.log(localStorage.getItem("subAgentID"));
      let responseData = bookingDetails(localStorage.getItem("subAgentID"));

      Promise.resolve(responseData).then((result) => {
        updateRows(result.data);
        setData(result.data);
      });
    }
  }, []);
  console.log(data);
  console.log(rows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeSearch = (event) => {
    let strSearchData = event.toString().toLowerCase();
    let arrData = rows;
    let arrNewData = [];
    if (strSearchData === undefined || strSearchData === "") {
      updateRows(data);
    } else {
      arrData.forEach((seriesObj) => {
        let selectedBookingAgent = seriesObj.sub_agent_name
          .toString()
          .toLowerCase();
        let booking_no = seriesObj.booking_no.toString().toLowerCase();
        let arrival_airport_name = seriesObj.arrival_airport_name
          .toString()
          .toLowerCase();
        let departure_airport_name = seriesObj.departure_airport_name
          .toString()
          .toLowerCase();
        if (
          selectedBookingAgent.includes(strSearchData) ||
          booking_no.includes(strSearchData) ||
          arrival_airport_name.includes(strSearchData) ||
          departure_airport_name.includes(strSearchData)
        ) {
          arrNewData.push(seriesObj);
        }
      });
      updateRows(arrNewData);
    }
  };

  const handleCancelSearch = () => {
    updateRows(data);
  };

  function handleSorting(name) {
    let sortingData = [];
    if (name === "bookingAgent") {
      if (sortBookingAgent === "desc") {
        sortingData = [...data].sort((a, b) => {
          if (a.sub_agent_name < b.sub_agent_name) return -1;
          if (a.sub_agent_name > b.sub_agent_name) return 1;
          return 0;
        });
        updateSortingBookingAgent("asc");
        updateRows(sortingData);
      } else {
        sortingData = [...data].sort((a, b) => {
          if (a.sub_agent_name < b.sub_agent_name) return 1;
          if (a.sub_agent_name > b.sub_agent_name) return -1;
          return 0;
        });
        updateSortingBookingAgent("desc");
        updateRows(sortingData);
      }
    }
    if (name === "bookingNumber") {
      if (sortBookingNumber === "desc") {
        sortingData = [...data].sort((a, b) => {
          if (a.booking_no < b.booking_no) return -1;
          if (a.booking_no > b.booking_no) return 1;
          return 0;
        });
        updateSortingBookingNumber("asc");
        updateRows(sortingData);
      } else {
        sortingData = [...data].sort((a, b) => {
          if (a.booking_no < b.booking_no) return 1;
          if (a.booking_no > b.booking_no) return -1;
          return 0;
        });
        updateSortingBookingNumber("desc");
        updateRows(sortingData);
      }
    } else if (name === "departureAirport") {
      if (sortDepartureAirport === "desc") {
        sortingData = [...data].sort((a, b) => {
          if (a.departure_airport_name < b.departure_airport_name) return -1;
          if (a.departure_airport_name > b.departure_airport_name) return 1;
          return 0;
        });
        updateSortingDepartureAirport("asc");
        updateRows(sortingData);
      } else {
        sortingData = [...data].sort((a, b) => {
          if (a.departure_airport_name < b.departure_airport_name) return 1;
          if (a.departure_airport_name > b.departure_airport_name) return -1;
          return 0;
        });
        updateSortingDepartureAirport("desc");
        updateRows(sortingData);
      }
    } else if (name === "arrivalAirport") {
      if (sortArrivalAirport === "desc") {
        sortingData = [...data].sort((a, b) => {
          if (a.arrival_airport_name < b.arrival_airport_name) return -1;
          if (a.arrival_airport_name > b.arrival_airport_name) return 1;
          return 0;
        });
        updateSortingArrivalAirport("asc");
        updateRows(sortingData);
      } else {
        sortingData = [...data].sort((a, b) => {
          if (a.arrival_airport_name < b.arrival_airport_name) return 1;
          if (a.arrival_airport_name > b.arrival_airport_name) return -1;
          return 0;
        });
        updateSortingArrivalAirport("desc");
        updateRows(sortingData);
      }
    } else if (name === "bookingDate") {
      if (sortBookingDate === "desc") {
        sortingData = [...data].sort((a, b) => {
          if (a.travel_date < b.travel_date) return -1;
          if (a.travel_date > b.travel_date) return 1;
          return 0;
        });
        updateSortingBookingDate("asc");
        updateRows(sortingData);
      } else {
        sortingData = [...data].sort((a, b) => {
          if (a.travel_date < b.travel_date) return 1;
          if (a.travel_date > b.travel_date) return -1;
          return 0;
        });
        updateSortingBookingDate("desc");
        updateRows(sortingData);
      }
    } else if (name === "passengerCount") {
      if (sortPassengerCount === "desc") {
        sortingData = [...data].sort((a, b) => {
          if (a.no_of_passengers < b.no_of_passengers) return -1;
          if (a.no_of_passengers > b.no_of_passengers) return 1;
          return 0;
        });
        updateSortingPassengerCount("asc");
        updateRows(sortingData);
      } else {
        sortingData = [...data].sort((a, b) => {
          if (a.no_of_passengers < b.no_of_passengers) return 1;
          if (a.no_of_passengers > b.no_of_passengers) return -1;
          return 0;
        });
        updateSortingPassengerCount("desc");
        updateRows(sortingData);
      }
    } else if (name === "ticket_price") {
      if (sortPassengerCount === "desc") {
        sortingData = [...data].sort((a, b) => {
          if (a.ticket_price < b.ticket_price) return -1;
          if (a.ticket_price > b.ticket_price) return 1;
          return 0;
        });
        updateSortingPassengerCount("asc");
        updateRows(sortingData);
      } else {
        sortingData = [...data].sort((a, b) => {
          if (a.ticket_price < b.ticket_price) return 1;
          if (a.ticket_price > b.ticket_price) return -1;
          return 0;
        });
        updateSortingPassengerCount("desc");
        updateRows(sortingData);
      }
    } else if (name === "bookingStatus") {
      if (sortbookingStatus === "desc") {
        sortingData = [...data].sort((a, b) => {
          if (a.status < b.status) return -1;
          if (a.status > b.status) return 1;
          return 0;
        });
        updateSortingBookingStatus("asc");
        updateRows(sortingData);
      } else {
        sortingData = [...data].sort((a, b) => {
          if (a.status < b.status) return 1;
          if (a.status > b.status) return -1;
          return 0;
        });
        updateSortingBookingStatus("desc");
        updateRows(sortingData);
      }
    }
  }

  return (
    <>
      {/* DataTable */}
      <SahoBookingListForm
        handleChangePage={handleChangePage}
        handleChangeSearch={handleChangeSearch}
        handleCancelSearch={handleCancelSearch}
        handleSorting={handleSorting}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={rows}
        sortBookingAgent={sortBookingAgent}
        sortBookingNumber={sortBookingNumber}
        sortDepartureAirport={sortDepartureAirport}
        sortArrivalAirport={sortArrivalAirport}
        sortBookingDate={sortBookingDate}
        sortPassengerCount={sortPassengerCount}
        sortbookingStatus={sortbookingStatus}
        setRowsPerPage={setRowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

export default SahoBookingList;
