import React, { useState } from "react";
import { passwordReset } from "./SahoService";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import SahoPasswordResetForm from "./Forms/SahoPasswordResetForm.js";

const SahoPasswordReset = () => {
  const [state, setState] = useState({
    oldpassword: "",
    newpassword: "",
    showNewPassword: false,
    showOldPassword: false,
  });
  // const [redirect,setredirect] = useState(false);
  const [error, setError] = useState({
    oldpasswordErr: "",
    newpasswordErr: "",
  });

  const handleClickShowNewPassword = () => {
    setState({ ...state, showNewPassword: !state.showNewPassword });
  };
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowOldPassword = () => {
    setState({ ...state, showOldPassword: !state.showOldPassword });
  };
  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };


  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Function to submit the new password
  const history = useHistory();
  const submitForm = () => {
    if (state.oldpassword === "") {
      setError({ ...error, oldpasswordErr: "Please Enter Old Password" });
    } else if (state.newpassword === "") {
      setError({ ...error, newpasswordErr: "Please Enter New Password" });
    } else {
      setError({
        ...error,
        oldpasswordErr: "",
        newpasswordErr: "",
      });
      let reqParams = {
        user_id: localStorage.getItem("userID"),
        old_password: state.oldpassword,
        new_password: state.newpassword,
      };

      let responseData = passwordReset(reqParams);

      Promise.resolve(responseData).then((res) => {
        if (res.status == 1) {
          enqueueSnackbar(res.message, {
            variant: "success",
            preventDuplicate: true,
          });
          setTimeout(() => {
            localStorage.removeItem("userID");
            localStorage.removeItem("userName");
            localStorage.removeItem("userRole");
            localStorage.removeItem("subAgentID");
            window.localStorage.clear();
            history.go("/login");
          }, "500");
        } else if (res.status == 2) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
        setState({
          oldpassword: "",
          newpassword: "",
        });
      });
    }
  };

  const handleProfileChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  return (
    <SahoPasswordResetForm
      state={state}
      error={error}
      submitForm={submitForm}
      handleProfileChange={handleProfileChange}
      handleClickShowNewPassword={handleClickShowNewPassword}
      handleMouseDownNewPassword={handleMouseDownNewPassword}
      handleClickShowOldPassword={handleClickShowOldPassword}
      handleMouseDownOldPassword={handleMouseDownOldPassword}
    />
  );
};

export default SahoPasswordReset;
