import React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function SahoCustomersForm({
  handleQueryChange,
  handleCancelSearch,
  columns,
  rows,
}) {
  return (
    <div>
      <div className="customer_list">
        <div className="customerlist_table">
          <h3>Customers List</h3>
          <div className="customerlist_search">
            {/* <SearchBar
              className="SearchBar"
              onChange={handleQueryChange}
              onCancelSearch={handleCancelSearch}
            /> */}
          </div>

          <DataGrid
            variant="outlined"
            shape="rounded"
            rows={rows}
            columns={columns}
            className="dataGrid_customerlist"
            // columns={[
            //    { field: "name", width: 200, headerName: "Name" },
            //    { field: "address", headerName: "Address", width: 200 },
            //    { field: "is_agency", headerName: "Agency" },
            //  ]}
            pageSize={10}
            autoHeight
            getRowId={(row) => row.id}
            disableSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
}
