import React, { useState, useEffect } from "react";
import { SAHO_API_URL } from "../config/SahoConstants";
import axios from "axios";
import { AddCredits, updateStatus,ticketBookingAccess} from "./SahoService";
import SahoSubAgentDataForm from "./Forms/SahoSubAgentDataForm";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SahoSubAgentData() {
  const [credit, setCredit] = useState([]);
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //const [addcreditERR, setAddCreditERR] = useState("");
  const [id, setId] = useState("");
  const [sub_agent_credit, setSub_agent_credit] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [account_number, setAccount_number] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [data, updateData] = useState(true);
  const [branch_name, setbranch_name] = useState("");
  const [addcredit, setAddCredit] = useState("");
  const [narration, setNarration] = useState("");
  const [page, setPage] = useState(0);
  const updateCredit = (id, sub_agent_credit, narration) => {
    if (addcredit === "") {
      enqueueSnackbar("Please enter valid credit amount", {
        variant: "error",
        preventDuplicate: true,
      });
    } else {
      let reqParams = {
        sub_agent_credit: addcredit,
        narration: narration,
        created_by: localStorage.getItem("userID"),
        id: id,
      };

      let responseData = AddCredits(reqParams);

      Promise.resolve(responseData).then((res) => {
        console.log(res);
        if (res.status == 1) {
          enqueueSnackbar(res.message, {
            variant: "success",
            preventDuplicate: true,
          });
          updateData(true);
        }
        else
        {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
          
        }
      });
    }

    setTimeout(() => {
      history.go("/addcredit");
    }, "1000");
  };

  const setStatus = (subagent_id,active,user_id) => {

    var status = !active;
       
    let reqParams = {
      sub_agent_id: subagent_id,
      status: status,
      user_id: user_id    
    };

    let responseData = updateStatus(reqParams);

      Promise.resolve(responseData).then((res) => {
        console.log(res);
        if (res.status == 1) {
          enqueueSnackbar(res.message, {
            variant: "success",
            preventDuplicate: true,
          });
          updateData(true);
        }
        else{
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });

        }
      });
  };

  const setTicketBookingAccess = (subagent_id,ticket_booking_access,user_id) => {

    
    var status = !ticket_booking_access;
       
    let reqParams = {
      sub_agent_id: subagent_id,
      ticket_booking_access: status,
      user_id: user_id    
    };

    console.log("reqParams",reqParams);

    let responseData = ticketBookingAccess(reqParams);

      Promise.resolve(responseData).then((res) => {
        console.log(res);
        if (res.status == 1) {
          enqueueSnackbar(res.message, {
            variant: "success",
            preventDuplicate: true,
          });
          updateData(true);
        }
        else{
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });

        }
      });
  };

  useEffect(() => {
    if (data == true) {
      axios
        .get(SAHO_API_URL + "sub-agents-view")
        .then((res) => {
          console.log("Credit:");
          console.log(res.data.data);
          setCredit(res.data.data);
        })
        .catch((err) => console.log(err));
      updateData(false);     
    }
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div>
      <SahoSubAgentDataForm
        credit={credit}
        id={id}
        setId={setId}
        sub_agent_credit={sub_agent_credit}
        setSub_agent_credit={setSub_agent_credit}
        notify={notify}
        handleOpen={handleOpen}
        handleClose={handleClose}
        setAccount_number={setAccount_number}
        setNarration={setNarration}
        narration={narration}
        account_number={account_number}
        ifsc={ifsc}
        setIfsc={setIfsc}
        branch_name={branch_name}
        setbranch_name={setbranch_name}
        page={page}
        updateCredit={updateCredit}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        style={style}
        open={open}
        setAddCredit={setAddCredit}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setStatus={setStatus}
        setTicketBookingAccess={setTicketBookingAccess}
      />
    </div>
  );
}

export default SahoSubAgentData;
