import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell
} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";

const StyledTableCell = {
  head: {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.658)",
    pt: 2,
    pb: 2,
    fontFamily: "Gothic",
  },
  body: {
    fontFamily: "Gothic",
    fontSize: 14,
  },
};
const StyledTableRow = {
  root: {
    "&:nth-of-type(odd)": {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor:  "#f3f3f3"
    },
  },
};


const pagination = {ml:4/5};
export default function SahoTransactionForm({
  handleChangePage,
  page,
  rowsPerPage,
  rows,
  handleChangeRowsPerPage,
}) {
  //const classes = useStyles();

  return (
    <div className="SahoTransactionForm_conatiner">
      <Paper elevation={6} className="SahoTransactionForm_table">
        <h3>
          <ReceiptIcon
            sx={{ fontSize: 30 }}
            style={{ marginBottom: "0.8%", color: "#F7A440" }}
          />
          Payment Transaction
        </h3>

        <TableContainer component={Paper}>
          <Table
            /*className={classes.table}*/
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{StyledTableCell}}>Agent Name</TableCell >
                <TableCell sx={{StyledTableCell}} align="left">Arrival</TableCell >
                <TableCell sx={{StyledTableCell}} align="left">Departure</TableCell >
                <TableCell sx={{StyledTableCell}} align="left">Credit Pay</TableCell >
                <TableCell sx={{StyledTableCell}} align="left">Transaction Date</TableCell >
                <TableCell sx={{StyledTableCell}} align="left">Margin</TableCell >
              </TableRow>
            </TableHead>
            <TableBody>
             
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow sx={{StyledTableRow}} key={row.id}>
                    <TableCell sx={{StyledTableCell}} component="th" scope="row">
                      {row.sub_agent_name}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="left">
                      {row.arrival_airport_name}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="left">
                      {row.departure_airport_name}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="left" style={{ color: "red" }}>
                      <i className="fas fa-rupee-sign"> {row.ticket_price}</i>
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="left">
                      {row.created_at.substring(0, 10)}
                    </TableCell >
                    <TableCell sx={{StyledTableCell}} align="left" style={{ color: "green" }}>
                      <i className="fas fa-rupee-sign">
                        {(row.ticket_price * 2) / 100}{" "}
                      </i>
                    </TableCell >
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{pagination}}
          labelRowsPerPage=""
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // onChangePage={handleChangePage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
