import React, { useState, useEffect } from "react";
import { loginDetails, generateVerificationCode } from "./SahoService";
import SahoLoginForm from "./Forms/SahoLoginForm";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SahoLogin = (props) => {
  const [values, setValues] = React.useState({
    username: "",
    password: "",
    showPassword: false,
  });
  const [usernameError, setUsernameError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  const history = useHistory();
  const location = useLocation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const generateCode = (email) => {
    console.log("generateVerificationCode: email", email);

    let reqParams = {
      email: email,
    };

    let emailValidation = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    );
    if (email === "") {
      setEmailError("Please enter the email address");
    } else if (!emailValidation.test(email)) {
      setEmailError(
        "Email address entered is invalid. Please enter a valid email"
      );
    } else {
      setEmailError("");

      let responseData = generateVerificationCode(reqParams);

      Promise.resolve(responseData).then((res) => {
        if (res.status == 1) {
          enqueueSnackbar(res.message, {
            variant: "success",
            preventDuplicate: true,
          });
          history.push({
            pathname: "/verification-code",
            state: { email: email },
          });
        } else if (res.status == 2) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        } else if (res.status == 3) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
    }
  };

  const handleBlur = (prop) => (event) => {
    if (prop === "username") {
      let username = event.target.value;
      let emailValidation = new RegExp(
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
      );
      if (username === "") {
        setUsernameError("Please Enter The Username To Login");
      } else if (!emailValidation.test(username)) {
        setUsernameError(
          "Username You Entered Is Not Valid. Please Enter A Valid Username"
        );
      } else {
        setUsernameError("");
      }
    } else if (prop === "password") {
      let password = event.target.value;
      if (password === "") {
        setPasswordError("Please Enter The Password To Login");
      } else {
        setPasswordError("");
      }
    } else if (prop === "email") {
      setEmailError("");
    }
  };

  const submitForm = () => {
    let username = values["username"];
    let password = values["password"];
    if (username === "") {
      setUsernameError("Please Enter The Username To Login");
    } else if (password === "") {
      setPasswordError("Please Enter The Password To Login");
    } else {
      setUsernameError("");
      setPasswordError("");

      let data = {
        email: username,
        password: password,
      };
      let responseData = loginDetails(data);

      Promise.resolve(responseData).then((res) => {
        if (res.status === 1) {
          let userAdmin = false;
          if (res.data.userRole === "admin") {
            userAdmin = true;
          } else {
            userAdmin = false;
          }

          let resultData = {
            userName: res.data.userName,
            userEmail: res.data.userEmail,
            userID: res.data.userID,
            userRole: res.data.userRole,
            userLoggedIn: true,
            isAdmin: userAdmin,
            subAgentID: res.data.subAgentID,
            partnerID: res.data.partnerID,
            ticketBookingAccess: res.data.ticketBookingAccess,
            companyDetails: res.data.companyDetails
          };

          props.loginCheck(resultData);
        } else if (res.status === 2) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });

          setValues({ ...values, username: "" });
          setValues({ ...values, password: "" });
        } else if (res.status === 3) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });

          setValues({ ...values, username: "" });
          setValues({ ...values, password: "" });
        } else {
          enqueueSnackbar("Could not login at the moment.Please try gain", {
            variant: "error",
            preventDuplicate: true,
          });

          setValues({ ...values, username: "" });
          setValues({ ...values, password: "" });
        }
      });
    }
  };

  return (
    // DataTable
    <SahoLoginForm
      handleChange={handleChange}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      handleBlur={handleBlur}
      submitForm={submitForm}
      values={values}
      usernameError={usernameError}
      emailError={emailError}
      passwordError={passwordError}
      handleOpen={handleOpen}
      handleClose={handleClose}
      open={open}
      setEmail={setEmail}
      email={email}
      generateCode={generateCode}
      style
    />
  );
};

export default SahoLogin;
