import React from "react";
import {
  Button,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Grid,
} from "@mui/material";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Visibility, VisibilityOff, AccountCircle } from "@mui/icons-material";

export default function SahoLoginForm({
  handleChange,
  handleClickShowPassword,
  handleMouseDownPassword,
  handleBlur,
  submitForm,
  values,
  usernameError,
  passwordError,
  emailError,
  handleOpen,
  handleClose,
  open,
  generateCode,
  email,
  setEmail,
}) {
  return (
    <div className="login_mainContainer">
      <div className="login_table">
        <h3>Login</h3>

        <Grid
          container
          align="center"
          justifyContent="center"
          alignItems="center"
          columns={1}
          spacing={1}
        >
          <Grid item md={12}>
            <label htmlFor="outlined-adornment-username">Username </label>
            <OutlinedInput
              // classes="login_input"
              label="Username"
              id="outlined-adornment-username"
              value={values.username}
              onChange={handleChange("username")}
              onBlur={handleBlur("username")}
              aria-describedby="outlined-username-helper-text"
              error={usernameError > 0}
              className="input_login"
              inputProps={{
                "aria-label": "username",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <AccountCircle className="accountCircle_login" />
                </InputAdornment>
              }
              labelWidth={70}
            />
            <p>{usernameError}</p>
          </Grid>
          <Grid item md={12}>
            <label>Password </label>
            <OutlinedInput
              // classes="login_input"
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              onBlur={handleBlur("password")}
              error={passwordError > 0}
              className="input_login"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <Visibility className="visibility_login" />
                    ) : (
                      <VisibilityOff className="visibility_login" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            <p>{passwordError}</p>
          </Grid>
        </Grid>
        <div>
          <Button
            style={{
              marginTop: "25px",
              marginLeft: "60px",
            }}
            variant="contained"
            onClick={submitForm}
          >
            Login
          </Button>
          <Button
            style={{
              marginTop: "25px",
              marginRight: "25px",
              marginLeft: "10px",
            }}
            variant="contained"
            onClick={() => {
              handleOpen();
            }}
          >
            Forgot Password
          </Button>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To reset the password, please enter your email address here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
            onBlur={handleBlur("email")}
          />
          <p style={{ color: "red" }}>{emailError}</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              generateCode(email);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
