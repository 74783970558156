import React from "react";
import Notification from "../SahoNotification";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { FormControl, TextField, IconButton, Autocomplete, Grid } from "@mui/material";
import CreatableSelect from "react-select/creatable";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";


  const StyledTableCell = {
    head: {
      backgroundColor: "#f3f3f3",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.658)",
      pt: 2,
      pb: 2,
      fontFamily: "Gothic",
    },
    body: {
      fontFamily: "Gothic",
      fontSize: 14,
    },
  };
  const StyledTableRow = {
    root: {
      "&:nth-of-type(odd)": {
        //backgroundColor: theme.palette.action.hover,
        backgroundColor:  "#f3f3f3"
      },
    },
  };
  // const StyledTableSortLabel = {
  //   icon: {
  //     color: "	#FFFFFF",
  //     "& path": {
  //       fill: "#eee"
  //     }
  //   }
  // };

export const SahoCreateBookingForm = ({
  airportNameList,
  selectedValue,
  notify,
  setNotify,
  currentDate,
  departureError,
  arrivalError,
  customerError,
  dateError,
  bookingAgentError,
  tableContentError,
  subAgentList,
  paymentModeError,
  passengerValue,
  handleBookingDetailsChange,
  paymentMode,
  submitForm,
  data,
  rows,
  editrow,
  inputAge,
  setInputAge,
  inputPassport,
  setInputPassport,
  inputContact,
  setInputContact,
  inputAddress,
  SetInputAddress,
  addMorePassengers,
  handleEditChange,
  handleCancel,
  handleEditingDone,
  handleCustomerChange,
  handlePassengerNameChange,
  handleDeleting,
  handleEditing,
  handleBlur,
  inputCity,
  setInputCity,
  inputState,
  setInputState,
  inputPostal,
  setInputPostal,
  inputCountry,
  setInputCountry,
  updatecreditLimitErr,
  handleClose,
  handleOpen,
  open,
  style,
}) => {
  //const classes = useStyles();
  
   
  return (
    <div className="createBooking_conatiner">
      <div className="createBooking_table">
        
        <h3 className="create_booking">Create Ticket Booking</h3>

        <div className="booking_details">
          <h4>Booking Details</h4>
        </div>
        <br />
        <div className="bookingDetails_table">
          <div className="bookingDetails_subtable">
            <div className="row_add_booking">
              <div className="row_col_booking">
                <FormControl className="textField" variant="outlined">
                  <Autocomplete
                    
                    options={airportNameList}
                    getOptionLabel={(option) =>
                      option.name +
                      "-" +
                      option.code 
                    }
                    onChange={handleBookingDetailsChange("departureAirport")}
                    onBlur={handleBlur("departureAirport")}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Departure" />
                    )}
                  />
                  <p>{departureError}</p>
                </FormControl>
              </div>
              <div className="row_col_booking">
                <FormControl className="textField">
                  <Autocomplete
                    options={airportNameList}
                    getOptionLabel={(option) =>
                      option.name +
                      "-" +
                      option.code 
                    }
                    onChange={handleBookingDetailsChange("arrivalAirport")}
                    onBlur={handleBlur("arrivalAirport")}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Arrival" />
                    )}
                  />
                  <p>{arrivalError}</p>
                </FormControl>
              </div>
            </div>
            <div className="row_add_booking">
              {/* <div>
                <FormControl className="textField">
                  <Autocomplete
                    options={subAgentList>0}
                    getOptionLabel={(option) => option.name}
                    onChange={handleBookingDetailsChange("bookingAgent")}
                    onBlur={handleBlur("bookingAgent")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="primary"
                        placeholder="Booking Agent"
                      />
                    )}
                  />
                  <p>{bookingAgentError}</p>
                </FormControl>
              </div> */
              <div>
              <Autocomplete
             // disablePortal
              id="select-customer"
              options={selectedValue.selectOptions}
              sx={{ width: 250 }}
              onChange={handleBookingDetailsChange("selectedCustomer")}
              onBlur={handleBlur("selectedCustomer")}
              renderInput={(params) => <TextField {...params} label="Select Customer" />}
            />
             <p>{customerError}</p>
              </div>
              
              
              
              
              }
              <div className="createbook_input">
                <Input
                  label="Date"
                  type="date"
                  defaultValue ={currentDate}
                  className="textField"
                  value={data.travelDate}
                  inputlabelprops={{ shrink: true }}
                  onChange={handleBookingDetailsChange("travelDate")}
                  onBlur={handleBlur("travelDate")}
                  error={dateError > 0}
                  helpertext={dateError}
                  min={currentDate.split("/").reverse().join("-")}
                />
                 <p>{dateError}</p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="personalDetails">
          <div className="personalDetails_banner">
            <h4>Passenger details</h4>
          </div>
          <div className="personalDetails_table">
            <div className="personaldetails_dataForm">
              {rows.length > 0 &&
                rows.map((row) =>
                  row.name === editrow.name ? (
                    <Grid container>
                      <div className="editPersonalDetails_Cbooking" key={row.id}>
                        <div className="editPersonalDetails_left" key={row.id}>
                          <div key={row.id}>
                            <label htmlFor="">Name</label>
                            <Grid item md={6}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="name"
                                placeholder="Name"
                                autoComplete="on"
                                defaultValue={row.name}
                                onChange={handleEditChange("name")}
                              />
                            </Grid>
                            <label htmlFor="">Age</label>
                            <Grid item md={6}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="age"
                                placeholder="Age"
                                autoComplete="off"
                                defaultValue={row.age}
                                onChange={handleEditChange("age")}
                              />
                            </Grid>
                          </div>
                          <Grid container>
                            <label htmlFor="">Passport</label>
                            <Grid item md={6}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="passport"
                                placeholder="Passport"
                                autoComplete="off"
                                defaultValue={row.passportNumber}
                                onChange={handleEditChange("passportNumber")}
                              />
                            </Grid>
                            <label htmlFor="">Contact</label>
                            <Grid item md={6}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="contact"
                                placeholder="Contact"
                                autoComplete="off"
                                defaultValue={row.contact}
                                onChange={handleEditChange("contact")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <label htmlFor="">Street</label>
                            <Grid item md={12}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="address"
                                placeholder="street line"
                                multiline
                                autoComplete="off"
                                defaultValue={row.street}
                                onChange={handleEditChange("street")}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <div className="editPersonalDetails_right" key={row.id}>
                          <Grid container>
                            <label htmlFor="">City/District</label>
                            <Grid item md={6}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="address"
                                label="Address"
                                multiline
                                autoComplete="off"
                                defaultValue={row.city}
                                onChange={handleEditChange("city")}
                              />
                            </Grid>
                            <label htmlFor="">State</label>
                            <Grid item md={6}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="address"
                                placeholder="state"
                                multiline
                                autoComplete="off"
                                defaultValue={row.state}
                                onChange={handleEditChange("state")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <label htmlFor="">Postal code</label>
                            <Grid item md={6}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="address"
                                placeholder="postal code"
                                multiline
                                autoComplete="off"
                                defaultValue={row.postal}
                                onChange={handleEditChange("postal")}
                              />
                            </Grid>
                            <label htmlFor="">Country</label>
                            <Grid item md={6}>
                              <input
                                className="editPersonalDetails_Input"
                                type="text"
                                id="address"
                                placeholder="Country"
                                multiline
                                autoComplete="off"
                                defaultValue={row.country}
                                onChange={handleEditChange("country")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <label htmlFor=""></label>
                            <Grid item md={12}>
                              <IconButton onClick={handleEditingDone}>
                                <SaveIcon color="blue" />
                              </IconButton>

                              <IconButton onClick={handleCancel}>
                                <CloseIcon color="red" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    <TableContainer
                      component={Paper}
                      style={{ marginTop: "2%" }}
                      key={row.id}
                    >
                      <Table
                        // className={classes.table}
                        // size="small"
                        // aria-label="customized table"
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead key={row.id}>
                          <TableRow>
                            <TableCell sx={{StyledTableCell}}>Name</TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">Age</TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">
                              Passport Number
                            </TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">
                              Contact Number
                            </TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">
                              Address
                            </TableCell >
                            <TableCell sx={{StyledTableCell}} align="left"></TableCell >
                          </TableRow>
                        </TableHead>
                        <TableBody key={row.id}>
                          <TableRow sx={{StyledTableRow}} key={row.id}>
                            <TableCell sx={{StyledTableCell}} component="th" scope="row">
                              {row.name}
                            </TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">
                              {row.age}
                            </TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">
                              {row.passportNumber}
                            </TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">
                              {row.contact}
                            </TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">
                              { row.street}
                            </TableCell >
                            <TableCell sx={{StyledTableCell}} align="left">
                              <IconButton onClick={() => handleEditing(row)}>
                                <EditIcon color="blue" />
                              </IconButton>
                              <IconButton onClick={() => handleDeleting(row)}>
                                <DeleteIcon color="red" />
                              </IconButton>
                            </TableCell >
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                )}
            </div>
            <Button
              className="add_Passenger_btn"
              onClick={() => {
                handleOpen();
              }}
            >
              Add Passenger
              <AddIcon />
            </Button>
            {/* Modal */}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open} className="box">
                <Box sx={style}>
                  <div>
                    <Typography
                      id="transition-modal-title"
                     
                      component="h2"
                      className="modal_bodybooking_head"
                    >
                      <h5 className="modal_head">Add Passenger</h5>

                      <IconButton
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <CloseIcon color="red" />
                      </IconButton>
                    </Typography>
                  </div>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="" className="modal_bodybooking_name">
                      Passenger Name :
                    </label>
                    <CreatableSelect
                      className="textField_main"
                      placeholder="SELECT"
                      variant="outlined"
                      options={passengerValue.selectOptions}
                      id="passengername"
                      value={passengerValue.selectOptions.name}
                      onChange={(e) => handlePassengerNameChange(e)}
                    />
                  </Typography>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="" className="modal_bodybooking_age">
                      Age:
                    </label>
                    <input
                      type="number"
                      placeholder="Age"
                      variant="outlined"
                      id="age"
                      value={inputAge}
                      onChange={(e) => setInputAge(e.target.value)}
                    />
                  </Typography>

                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="">Contact Number:</label>
                    <input
                      placeholder="Contact Number"
                      type="number"
                      id="contact"
                      value={inputContact}
                      onChange={(e) => setInputContact(e.target.value)}
                    />
                  </Typography>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="" className="modal_bodybookingamt">
                      Passport Number:
                    </label>
                    <input
                      placeholder="Passport Number"
                      variant="outlined"
                      id="passport"
                      value={inputPassport}
                      onChange={(e) => setInputPassport(e.target.value)}
                    />
                  </Typography>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="" className="modal_bodybookingamt">
                      Street line :
                    </label>
                    <input
                      placeholder="street line"
                      variant="outlined"
                      id="address"
                      multiline
                      value={inputAddress}
                      onChange={(e) => SetInputAddress(e.target.value)}
                    />
                  </Typography>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="" className="modal_bodybookingamt">
                      City / District :
                    </label>
                    <input
                      variant="outlined"
                      placeholder="city/district"
                      type="text"
                      autoComplete="off"
                      value={inputCity}
                      onChange={(e) => setInputCity(e.target.value)}
                    />
                  </Typography>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="" className="modal_bodybookingamt">
                      State :
                    </label>
                    <input
                      placeholder="state"
                      type="text"
                      autoComplete="off"
                      value={inputState}
                      onChange={(e) => setInputState(e.target.value)}
                    />
                  </Typography>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="" className="modal_bodybookingamt">
                      Postal code :
                    </label>
                    <input
                      placeholder="postal code"
                      type="text"
                      autoComplete="off"
                      value={inputPostal}
                      onChange={(e) => setInputPostal(e.target.value)}
                    />
                  </Typography>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_bodybooking"
                  >
                    <label htmlFor="" className="modal_bodybookingamt">
                      Country :
                    </label>
                    <input
                      placeholder="country"
                      type="text"
                      autoComplete="off"
                      value={inputCountry}
                      onChange={(e) => setInputCountry(e.target.value)}
                    />
                  </Typography>

                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    className="modal_btn"
                  >
                    <Button
                      onClick={() => {
                        addMorePassengers();
                        handleClose();
                      }}
                    >
                      Add Passenger
                    </Button>
                  </Typography>
                </Box>
              </Fade>
            </Modal>

            <div className="createbooking_btn">
              <Button onClick={submitForm}>Create Booking</Button>
            </div>

            <label className="tableContentError">{tableContentError}</label>
            <label className="tableContentError">{updatecreditLimitErr}</label>
          </div>
        </div>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
