import React, { useState, useEffect } from "react";
import { generateVerificationCode, passwordVerification } from "./SahoService";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import SahoVerificationCodeForm from "./Forms/SahoVerificationCodeForm.js";
import { useLocation } from "react-router-dom";

const SahoVerificationCode = () => {
  const [state, setState] = useState({
    verificationcode: "",
  });

  const [error, setError] = useState({
    verificationcodeErr: "",
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const [email, setEmail] = useState("");

  // Function to submit the new password
  const history = useHistory();

  useEffect(() => {
    console.log("email from login page redirect", location.state.email);
    setEmail(location.state.email);
  }, []);

  const resendCode = () => {
    let reqParams = {
      email: email,
    };

    console.log("resend", reqParams);
    let responseData = generateVerificationCode(reqParams);

    Promise.resolve(responseData).then((res) => {
      if (res.status == 1) {
        enqueueSnackbar(res.message, {
          variant: "success",
          preventDuplicate: true,
        });
        history.push({
          pathname: "/verification-code",
          state: { email: email },
        });
      } 
      else if (res.status == 2) {
        enqueueSnackbar(res.message, {
          variant: "success",
          preventDuplicate: true,
        });        
      }
      else if (res.status == 3) {
        enqueueSnackbar(res.message, {
          variant: "success",
          preventDuplicate: true,
        });        
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  };

  const submitVerificationCode = () => {
    if (state.verificationcode === "") {
      setError({
        ...error,
        verificationcodeErr: "Please enter the verification code",
      });
    } else {
      setError({
        ...error,
        verificationcodeErr: "",
      });

      let reqParams = {
        email: email,
        verification_code: state.verificationcode,
      };

      console.log("submitVerificationCode", reqParams);

      let responseData = passwordVerification(reqParams);

      Promise.resolve(responseData).then((res) => {
        if (res.status == 1) {
          history.push({
            pathname: "/forgot-password",
            state: { email: email },
          });
        } else if (res.status == 2) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        } 
        else if (res.status == 3) {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }else {
          enqueueSnackbar(res.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
        setState({
          verificationcode: "",
        });
      });
    }
  };

  const handleProfileChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  return (
    <SahoVerificationCodeForm
      state={state}
      error={error}
      submitVerificationCode={submitVerificationCode}
      handleProfileChange={handleProfileChange}
      resendCode={resendCode}
    />
  );
};

export default SahoVerificationCode;
