import React, { useState } from "react";
import { addCustomer } from "./SahoService";
import { useHistory } from "react-router-dom";
// import SahoAddNewCustomerForm from "./Forms/SahoAddNewCustomerForm";
import SahoAddNewCustomerForm from "./Forms/SahoAddNewCustomerForm";
import { useSnackbar } from "notistack";

const SahoAddNewCustomer = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [state, setState] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    is_agency: "",
    responseData: [],
  });
  const [error, setError] = useState({
    nameErr: "",
    streetErr: "",
    cityErr: "",
    stateErr: "",
    pincodeErr: "",
    countryErr: "",
    agencyErr: "",
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Function to Submit the customer details
  const history = useHistory();
  const submitForm = () => {
    if (state.name === "") {
      setError({ ...error, nameErr: "Please Enter Name" });
    }
    // else if (state.is_agency === "") {
    //   setError({ ...error, agencyErr: "Please Check Agency" });
    // }
    // else if (state.address === "") {
    //   setError({ ...error, addressErr: "Please Enter Address" });
    // }
    else if (state.street === "") {
      setError({ ...error, streetErr: "Please Enter Street" });
    } else if (state.city === "") {
      setError({ ...error, cityErr: "Please Enter City" });
    } else if (state.state === "") {
      setError({ ...error, stateErr: "Please Enter State" });
    } else if (state.pincode === "") {
      setError({ ...error, pincodeErr: "Please Enter Pincode" });
    } else if (state.country === "") {
      setError({ ...error, countryErr: "Please Enter Country" });
    } else {
      setError({
        ...error,
        nameErr: "",
        streetErr: "",
        cityErr: "",
        stateErr: "",
        pincodeErr: "",
        countryErr: "",
        // addressErr: "",
        // agencyErr: "",
      });
      let reqParams = {
        name: state.name,
        sub_agent_id: localStorage.getItem("subAgentID"),
        // address: state.address,
        street: state.street,
        city: state.city,
        state: state.state,
        pincode: state.pincode,
        country: state.country,
        is_agency: "true",
        created_by: localStorage.getItem("userID"),
      };

      console.log("REquired params in SAhoADDNewCustomer.js");
      console.log(reqParams);

      var cust_id;

      let responseData = addCustomer(reqParams);

      Promise.resolve(responseData).then((result) => {

        if(result.data.length>=1)
        {
          if (result.status == "1") 
          {
            cust_id = result.data[0].id;

                  enqueueSnackbar("Customer added successfully",  {
                  variant: 'success',  preventDuplicate: true
                  });
            // setNotify({
            //   isOpen: true,
            //   message: "Added Successfully",
            //   type: "success",
            // });
                              setState({
                              name: "",
                              sub_agent_id: "",
                              // address: "",
                              street: "",
                              city: "",
                              state: "",
                              pincode: "",
                              country: "",
                              is_agency: "",
                            });
                            history.push("/customer-list");
          } 
            else 
            {
                enqueueSnackbar("Failed to add New Customer", {
                  variant: 'error',  preventDuplicate: true
            });
            // setNotify({
            //   isOpen: true,
            //   message: "Failed to add New Customer",
            //   type: "error",
            // });
            }
          
        }  
          else 
          {
                  enqueueSnackbar("Network Error", {
                  variant: 'error',  preventDuplicate: true
              });
          // setNotify({ isOpen: true, message: "Network Error", type: "error" });
          }

        const query = new URLSearchParams(window.location.search).get(
          "mainPage"
        );

        if (query == "booking") {
          let path = `create-booking`;
          let searchParam = "?cust_id=" + cust_id;

          history.go({
            pathname: path,
            search: searchParam,
          });
        }
      });
    }
  };

  const handleCustomerChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
    //   if (prop === "name") {
    //   let name = event.target.value;
    //   setState({ ...state, [prop]: name });
    //   setError({ ...error, nameErr: "" });
    // } else if (prop === "street") {
    //   let street = event.target.value;
    //   setState({ ...state, [prop]: street });
    //   setError({ ...error, streetErr: "" });
    // } else if (prop === "city") {
    //   let city = event.target.value;
    //   setState({ ...state, [prop]: city });
    //   setError({ ...error, cityErr: "" });
    // } else if (prop === "state") {
    //   let state = event.target.value;
    //   setState({ ...state, [prop]: state });
    //   setError({ ...error, stateErr: "" });
    // } else if (prop === "pincode") {
    //   let pincode = event.target.value;
    //   setState({ ...state, [prop]: pincode });
    //   setError({ ...error, pincodeErr: "" });
    // } else if (prop === "country") {
    //   let country = event.target.value;
    //   setState({ ...state, [prop]: country });
    //   setError({ ...error, countryErr: "" });
    // }

    //already commented
    //  else if (prop === "address") {
    //   let address = event.target.value;
    //   setState({ ...state, [prop]: address });
    //   setError({ ...error, addressErr: "" });
    // } else if (prop === "is_agency") {
    //   let is_agency = event.target.value;
    //   setState({ ...state, [prop]: is_agency });
    //   setError({ ...error, agencyErr: "" });
    // }
  };

  return (
    // DataTable
    <SahoAddNewCustomerForm
      notify={notify}
      setNotify={setNotify}
      state={state}
      error={error}
      submitForm={submitForm}
      handleCustomerChange={handleCustomerChange}
    />
  );
};

export default SahoAddNewCustomer;
