import React from "react";

import {  IconButton,TextField,InputAdornment,OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff, AccountCircle } from "@mui/icons-material";


import Button from "@mui/material/Button";
import { InputLabel, Grid } from "@mui/material";

export default function SahoPasswordResetForm({
  state,
  error,
  submitForm,
  handleProfileChange,
  handleClickShowOldPassword,
  handleClickShowNewPassword,
  handleMouseDownNewPassword,
  handleMouseDownOldPassword
}) {
  return (
    <div className="login_mainContainer">
      <div className="login_table">
        <h3>Update Password</h3>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          columns={1}
          spacing={1}
          item
          xs={8}
        >
          <Grid>
            <TextField
              style={{ marginTop: "25px", marginLeft: "25px" }}
              id="outlined-adornment-password"
              type={state.showOldPassword ? 'text' : 'password'}
              placeholder="Old Password"
              variant="outlined"
              fullwidth
              value={state.oldpassword}
              onChange={handleProfileChange("oldpassword")}
              error={error.oldpasswordErr ? true : false}
              helperText={error.oldpasswordErr}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //       aria-label="toggle password visibility"
              //       onClick={handleClickShowOldPassword}
              //       onMouseDown={handleMouseDownOldPassword}
              //       edge="end"
              //     >
              //       {state.showOldPassword ? <VisibilityOff /> : <Visibility />}
              //     </IconButton>
              //   </InputAdornment>
              // }
            />
          </Grid>
          <Grid>
            <TextField
              style={{ marginTop: "25px", marginLeft: "25px" }}
              id="outlined-adornment-password"
              type={state.showNewPassword ? 'text' : 'password'}
              placeholder="New Password"
              variant="outlined"
              fullwidth
              value={state.newpassword}
              onChange={handleProfileChange("newpassword")}
              error={error.newpasswordErr ? true : false}
              helperText={error.newpasswordErr}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //       aria-label="toggle password visibility"
              //       onClick={handleClickShowNewPassword}
              //       onMouseDown={handleMouseDownNewPassword}
              //       edge="end"
              //     >
              //       {state.showNewPassword ? <VisibilityOff /> : <Visibility />}
              //     </IconButton>
              //   </InputAdornment>
              // }
            />
          </Grid>
        </Grid>

        <Button
          style={{
            marginTop: "25px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
          onClick={submitForm}
          variant="contained"
          size="large"
        >
          Update
        </Button>
      </div>
    </div>
  );
}
