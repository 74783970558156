import React from "react";

import { TextField } from "@mui/material";
import Notification from "../SahoNotification";
import Button from "@mui/material/Button";
export default function SahoAddNewCustomerFormBoot({
  notify,
  setNotify,
  state,
  error,
  submitForm,
  handleCustomerChange,
}) {
  return (
    <div className="newCustomer_conatiner">
      <div className="newCustomer_table">
        <h3>New Customer</h3>
        <div className="newCustomer_addNewCustomer">
          <h5>Add New Customer</h5>
        </div>
        <div className="newCustomer_dataField">
          <div className="">
            <div>
              <TextField
                id="outlined-multiline-static"
                placeholder="Name of customer"
                variant="outlined"
                fullWidth
                value={state.name}
                onChange={handleCustomerChange("name")}
                error={error.nameErr ? true : false}
                helperText={error.nameErr}
              />
            </div>
            {/* <Col>
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                    error={error.agencyErr ? true : false}
                >
                  Agency
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  labelId="demo-simple-select-outlined-label"
                  label="Agency"
                  variant="outlined"
                    onBlur={handleCustomerChange("is_agency")}
                    error={error.agencyErr ? true : false}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                <FormHelperText className="agencyErr">
                  {error.agencyErr}
                </FormHelperText>
              </FormControl>
            </Col> */}
          </div>
          <div>
            <div>
              <TextField
                style={{ marginTop: "25px" }}
                id="outlined-multiline-static"
                placeholder="Street Line"
                variant="outlined"
                // multiline
                // rows={3}
                fullWidth
                value={state.street}
                onChange={handleCustomerChange("street")}
                error={error.streetErr ? true : false}
                helperText={error.streetErr}
              />
            </div>
          </div>
          <div className="row_add">
            <div className="row_col">
              <TextField
                style={{ marginTop: "25px" }}
                id="outlined-multiline-static"
                placeholder="city/district"
                variant="outlined"
                // multiline
                // rows={3}
                fullWidth
                value={state.city}
                onChange={handleCustomerChange("city")}
                error={error.cityErr ? true : false}
                helperText={error.cityErr}
              />
            </div>
            <div className="row_col2">
              <TextField
                style={{ marginTop: "25px" }}
                id="outlined-multiline-static"
                placeholder=" state"
                variant="outlined"
                // multiline
                // rows={3}
                fullWidth
                value={state.state}
                onChange={handleCustomerChange("state")}
                error={error.stateErr ? true : false}
                helperText={error.stateErr}
              />
            </div>
          </div>
          <div className="row_add">
            <div className="row_col">
              <TextField
                style={{ marginTop: "25px" }}
                id="outlined-multiline-static"
                placeholder=" pincode "
                variant="outlined"
                // multiline
                // rows={3}
                fullWidth
                value={state.pincode}
                onChange={handleCustomerChange("pincode")}
                error={error.pincodeErr ? true : false}
                helperText={error.pincodeErr}
              />
            </div>
            <div className="row_col2">
              <TextField
                style={{ marginTop: "25px" }}
                id="outlined-multiline-static"
                placeholder=" country"
                variant="outlined"
                // multiline
                // rows={3}
                fullWidth
                value={state.country}
                onChange={handleCustomerChange("country")}
                error={error.countryErr ? true : false}
                helperText={error.countryErr}
              />
            </div>
          </div>

          <div className="newCustomer_Button">
            <Button
              style={{
                marginTop: "25px",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              onClick={submitForm}
              variant="primary"
              size="large"
            >
              Submit
            </Button>
          </div>
          <Notification notify={notify} setNotify={setNotify} />
        </div>
      </div>
    </div>
  );
}
