import React, { useState, useEffect } from "react";
import {
  generateStatementData,
  getAppSettings,
  viewStatementData,
  downloadStatementData,
  getInvoices,
} from "./SahoService";

import SahoStatementsListForm from "./Forms/SahoStatementsListForm";
import SahoStatementsForm from "./Forms/SahoStatementsForm";
import { useSnackbar } from "notistack";
import download from "downloadjs";
import { fontStyle, padding } from "@mui/system";
import contactDetails from '../config/contactDetails.json';


const SahoStatements = () => {
  let partnerIDs = [];
  const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
  partnerIDs = companyDetails.map((company) => company.partnerID);

  const [dateValues, setdateValues] = useState({
    start_date: "",
    end_date: "",
    payment_date_from: "",
    payment_date_to: "",
    responseData: [],
  });

  const [error, setError] = useState({
    startDateErr: "",
    endDateErr: "",
    payment_date_fromErr: "",
    payment_date_toErr: "",
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, updateRows] = useState([]);
  const [data, updateData] = useState(true);
  const [paymentDateFrom, updatePaymentDateFrom] = useState(false);
  const [paymentDateTo, updatePaymentDateTo] = useState(false);
  const [systemDate, setSystemDate] = useState();
  const [creditBalance, setCreditBalance] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoadingBalance, setisLoadingBalance] = useState(true);
  const [partnerID, setPartnerID] = useState(partnerIDs[0]);
  const [selectedPartner, setSelectedPartner] = useState(partnerIDs[0]);
  const [contactPhone, setContactPhone] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const handlePartnerIDChange = (event) => {
    const newPartnerID = event.target.value;
    setSelectedPartner(newPartnerID);
    let new_partner_id = parseInt(newPartnerID);
    setPartnerID(new_partner_id);
    updateData(true);
  };

  useEffect(() => {
    console.log("partner", partnerID);
    if (data == true) {
      let params = {
        partner_id: partnerID,
      };
      console.log("params", params);
      viewStatementData(params).then((result) => {
        updateRows(result.data);
        updateData(false);
      });
    }
  }, [data]);

  useEffect(() => {
    console.log("partner_id", partnerID);

     // Function to find company details based on companyID
     const findCompanyDetails = (companyID) => {
      const companyContactDetails = contactDetails.find(item => item.ID === companyID);
      if (companyContactDetails) {
        setContactPhone(companyContactDetails.Phone);
        setContactEmail(companyContactDetails.Email);
      }
    };

    // Find the companyID based on partnerID
    const companyID = companyDetails.find(item => item.partnerID === partnerID)?.companyID;

    if (companyID) {
      // Call the function to find and update the state variables
      findCompanyDetails(companyID);
    }

    let params = {
      odoo_model: "cz.api.integration",
      odoo_method: "get_receivable_payable_of_partner",
      odoo_request_type: "data",
      partner_id: partnerID,
    };
    setisLoadingBalance(true);
    let responseData = getInvoices(params);
    console.log(responseData);
    responseData
      .then((res) => {
        setisLoadingBalance(false);
        setCreditBalance(res.credit_debit.credit);
      })
      .catch((err) => {
        console.log("err", err);
      });
    const strDateTimeStamp = new Date();
    let dateTimeStamp =
      "" +
      strDateTimeStamp.getDate().toString().padStart(2, "0") +
      "-" +
      (strDateTimeStamp.getMonth().toLocaleString("en-IN") + 1) +
      "-" +
      strDateTimeStamp.getFullYear();

    setSystemDate(dateTimeStamp);
  }, [partnerID]);

  const downloadStatement = (rowID) => {
    console.log(rowID);
    let statementValues = {
      partner_id: partnerID,
      rowID: rowID,
      odoo_request_type: "statement_download",
    };
    const strDateTimeStamp = new Date().toDateString();
    downloadStatementData(statementValues)
      .then((res) => {
        console.log(res.data);
        const blob = new Blob([res.data], { type: "application/pdf" });
        download(blob, `Partner Ledger`);
      })
      .catch((err) => {
        enqueueSnackbar("Failed to generate statement", {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };

  const submitForm = () => {
    let settings_params = { settings_key: "start_date" };
    getAppSettings(settings_params)
      .then((res) => {
        if (res.data.status == "1") {
          var startDate = res.data.data.settings_value;
          console.log(startDate);
          console.log(dateValues);
          if (dateValues.start_date === "") {
            setError({ ...error, startDateErr: "Please Enter Start Date" });
          } else if (dateValues.end_date === "") {
            setError({ ...error, endDateErr: "Please Enter End Date" });
          } else if (dateValues.payment_date_from === "") {
            setError({
              ...error,
              payment_date_fromErr: "Please Enter Payment From Date",
            });
          } else if (dateValues.payment_date_to === "") {
            setError({
              ...error,
              payment_date_toErr: "Please Enter Payment To Date",
            });
          } else if (dateValues.start_date > dateValues.end_date) {
            setError({
              ...error,
              startDateErr: "Start Date should be less than End Date",
            });
          } else if (
            dateValues.payment_date_from > dateValues.payment_date_to
          ) {
            setError({
              ...error,
              payment_date_fromErr:
                "Payment From Date should be less than Payment To Date",
            });
          } else if (startDate > dateValues.start_date) {
            setError({
              ...error,
              startDateErr:
                "Start Date should be greater than " +
                new Date(startDate).toLocaleDateString("en-IN"),
            });
          } else {
            setError({
              ...error,
              startDateErr: "",
              endDateErr: "",
              payment_date_fromErr: "",
              payment_date_toErr: "",
            });

            let reqParams = {
              partner_id: partnerID,
              start_date: dateValues.start_date,
              end_date: dateValues.end_date,
              payment_date_from: dateValues.payment_date_from,
              payment_date_to: dateValues.payment_date_to,
              odoo_request_type: "statement_generate",
              odoo_method: "get_custom_partner_ledger",
              odoo_model: "cz.api.integration",
            };

            let responseData = generateStatementData(reqParams);
            Promise.resolve(responseData).then((res) => {
              console.log(res);
              if (res.status == "1") {
                updateData(true);
                enqueueSnackbar(res.message, {
                  variant: "success",
                  preventDuplicate: true,
                });
              } else if (res.status == "0") {
                enqueueSnackbar(res.message, {
                  variant: "error",
                  preventDuplicate: true,
                });
              }
            });

            setdateValues({
              start_date: "",
              end_date: "",
              payment_date_from: "",
              payment_date_to: "",
            });
            updateData(true);
          }
        } else {
          enqueueSnackbar("Failed to generate statement", {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Failed to generate statement", {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };

  const handleCustomerChange = (prop) => (event) => {
    setdateValues({ ...dateValues, [prop]: event.target.value });
    if (prop === "start_date" && paymentDateFrom === false) {
      setdateValues({
        ...dateValues,
        ["start_date"]: event.target.value,
        ["payment_date_from"]: event.target.value,
      });
    } else if (prop === "end_date" && paymentDateTo === false) {
      setdateValues({
        ...dateValues,
        ["end_date"]: event.target.value,
        ["payment_date_to"]: event.target.value,
      });
    }
    console.log(dateValues);
  };

  const handlePaymentDateFrom = (prop) => (event) => {
    updatePaymentDateFrom(true);
    setdateValues({ ...dateValues, ["payment_date_from"]: event.target.value });
  };

  const handlePaymentDateTo = (prop) => (event) => {
    updatePaymentDateTo(true);
    setdateValues({ ...dateValues, ["payment_date_to"]: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <div>
      <SahoStatementsForm
        dateValues={dateValues}
        error={error}
        submitForm={submitForm}
        handleCustomerChange={handleCustomerChange}
        //handleStartDateChange={handleStartDateChange}
        handlePaymentDateFrom={handlePaymentDateFrom}
        handlePaymentDateTo={handlePaymentDateTo}
        creditBalance={creditBalance}
        systemDate={systemDate}
        isLoadingBalance={isLoadingBalance}
        handlePartnerIDChange={handlePartnerIDChange}
        selectedPartner={selectedPartner}
        companyDetails={companyDetails}
        contactPhone={contactPhone}
        contactEmail={contactEmail}
      />

      <SahoStatementsListForm
        handleChangePage={handleChangePage}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={rows}
        setRowsPerPage={setRowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        downloadStatement={downloadStatement}
      />
    </div>
  );
};

export default SahoStatements;
