import React from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Notification from "../SahoNotification";
import { Table } from '@mui/material';
import { Button } from '@mui/material';


export default function SahoTaxesFormBoot({
  handleEditChange,
  handleEditingDone,
  handleCancel,
  handleEditing,
  handleDeleting,
  addTax,
  submitForm,
  rows,
  taxName,
  taxCode,
  taxPercentage,
  tableContentError,
  setTaxName,
  setTaxCode,
  setTaxPercentage,
  editrow,
  notify,
  setNotify,
}) {
  return (
    <div className="SahoTaxesForm_conatiner">
      <div className="SahoTaxesForm_table">
        <h3>Taxes</h3>
        <Table striped className="SahoTaxes_subTable">
          <thead>
            <tr>
              <th>Tax Name</th>
              <th>Tax Code</th>
              <th>Tax Percentage</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 &&
              rows.map((row) =>
                row.taxName === editrow.taxName ? (
                
                  <tr className="input_tax" key={row}>
                   
                    <td>
                      <input
                        type="text"
                        defaultValue={row.taxName}
                        onChange={handleEditChange("taxName")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        defaultValue={row.taxCode}
                        onChange={handleEditChange("taxCode")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        defaultValue={row.taxPercentage}
                        onChange={handleEditChange("taxPercentage")}
                      />
                    </td>
                    <td>
                      <IconButton onClick={handleEditingDone}>
                        <SaveIcon className="saveIcon_tax" />
                      </IconButton>
                      <IconButton onClick={handleCancel}>
                        <CloseIcon color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                  
                ) : (
                  <tr key={row}>
                    <td>{row.taxName}</td>
                    <td>{row.taxCode}</td>
                    <td>{row.taxPercentage}</td>
                    <td>
                      <IconButton onClick={() => handleEditing(row)}>
                        <EditIcon className="editIcon_tax" />
                      </IconButton>

                      <IconButton onClick={() => handleDeleting(row)}>
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </td>
                  </tr>
                )
              )}
            <tr className="input_tax">
              <td>
                <input
                  type="text"
                  placeholder="Tax Name"
                  value={taxName}
                  onChange={(e) => setTaxName(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  placeholder="Tax Code"
                  value={taxCode}
                  onChange={(e) => setTaxCode(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  placeholder="Tax Percentage"
                  value={taxPercentage}
                  onChange={(e) => setTaxPercentage(e.target.value)}
                />
              </td>
              <td>
                <IconButton onClick={addTax}>
                  <AddCircleIcon
                    fontSize="large"
                    className="addCircleIcon_tax"
                  />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </Table>
        <label
          className="tax_error"
          inputprops={{
            disableUnderline: true,
            readOnly: true,
          }}
        >
          {tableContentError}
        </label>
        <div className="SahoTaxesForm_btn">
          <Button onClick={submitForm}>Create Tax</Button>
        </div>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
