import React from "react";
import Button from "@mui/material/Button";
import "../style/SahoRegisterSubAgent.css";
import { Select, InputLabel, Grid, TextField, Skeleton } from "@mui/material";
import Notification from "../SahoNotification";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
export default function SahoRegisterSubAgentFormBoot({
  submitForm,
  handleChange,
  handleBlur,
  data,
  nameError,
  usernameError,
  addressError,
  notify,
  setNotify,
  partnerIDError,
  partnerID,
  handlePartnerIDChange,
  isLoadingPartnerID,
  selectedCompanies,
  handleCompanyChange,
  companyID,
}) {
  return (
    <div className="registerSubAgent">
      <div className="registerSubAgent_table">
        <h3>
          <AccountCircleIcon
            sx={{ fontSize: 30 }}
            style={{ marginBottom: "-1.2%", color: "#F7A440" }}
          />
          Register Sub Agent
        </h3>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Sub-agent Email"
              fullWidth
              type="email"
              value={data.email}
              autoComplete="off"
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
              margin="normal"
            />
            <p>{usernameError}</p>
            <InputLabel
              className="register_selectLabel"
              id="demo-simple-select-outlined-label"
              margin="normal"
            >
              <h3
                style={{
                  fontFamily: "Gothic",
                  align: "left",
                }}
              >
                Companies
              </h3>
            </InputLabel>
            {isLoadingPartnerID ? (
              <Skeleton animation="wave" width={560} height={40} />
            ) : (
              <Select
                fullWidth
                multiple
                className="register_select"
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Company ID"
                labelWidth={120}
                value={selectedCompanies}
                onChange={handleCompanyChange}
                onBlur={handleBlur("companyID")}
                margin="normal"
              >
                {companyID}
              </Select>
            )}

            <p>{partnerIDError}</p>

            <InputLabel
              className="register_selectLabel"
              id="demo-simple-select-outlined-label"
              margin="normal"
            >
              <h3
                style={{
                  fontFamily: "Gothic",
                  align: "left",
                }}
              >
                Partner for Address
              </h3>
            </InputLabel>
            {isLoadingPartnerID ? (
              <Skeleton animation="wave" width={560} height={40} />
            ) : (
              <Select
                fullWidth
                className="register_select"
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Partner ID"
                labelWidth={120}
                value={data.partnerID}
                onChange={handlePartnerIDChange("partnerID")}
                onBlur={handleBlur("partnerID")}
                margin="normal"
              >
                {partnerID}
              </Select>
            )}

            <p>{partnerIDError}</p>
          </Grid>
          <Grid
            container
            item
            md={5}
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <TextField
              placeholder="Sub-agent Name"
              fullWidth
              value={data.name}
              autoComplete="off"
              onChange={handleChange("name")}
              onBlur={handleBlur("name")}
              margin="normal"
              padding="10px"
            />

            <p>{nameError}</p>

            <TextField
              placeholder="Street"
              fullWidth
              type="text"
              value={data.address}
              autoComplete="off"
              onChange={handleChange("address")}
              onBlur={handleBlur("address")}
              margin="normal"
            />

            <TextField
              placeholder="City/District"
              fullWidth
              type="text"
              value={data.city}
              autoComplete="off"
              onBlur={handleBlur("city")}
              onChange={handleChange("city")}
              margin="normal"
            />

            <TextField
              placeholder="state"
              fullWidth
              type="text"
              value={data.states}
              autoComplete="off"
              onBlur={handleBlur("states")}
              onChange={handleChange("states")}
              margin="normal"
            />

            <TextField
              placeholder="postal code"
              fullWidth
              type="text"
              value={data.pincode}
              autoComplete="off"
              onBlur={handleBlur("pincode")}
              onChange={handleChange("pincode")}
              margin="normal"
            />

            <TextField
              placeholder="Country"
              fullWidth
              type="text"
              value={data.country}
              autoComplete="off"
              onBlur={handleBlur("country")}
              onChange={handleChange("country")}
              margin="normal"
              autoFocus
            />

            <p>{addressError}</p>
          </Grid>
        </Grid>

        <div className="register_btn">
          <Button onClick={submitForm}>Submit</Button>
        </div>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </div>
  );
}
