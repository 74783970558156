import React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  Button,
} from "@mui/material";

const pagination = { ml: 4 / 5 };
const StyledTableCell = {
  head: {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.658)",
    pt: 2,
    pb: 2,
    fontFamily: "Gothic",
  },
  body: {
    fontFamily: "Gothic",
    fontSize: 14,
  },
};
const StyledTableRow = {
  root: {
    "&:nth-of-type(odd)": {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor: "#f3f3f3",
    },
  },
};
const StyledTableSortLabel = {
  icon: {
    color: "	#FFFFFF",
    "& path": {
      fill: "#eee",
    },
  },
};

export default function SahoInvoicesListForm({
  handleChangePage,
  page,
  rowsPerPage,
  rows,
  handleChangeRowsPerPage,
  generateInvoice,
  head
}) {
  return (
    <div className="root_booklist">
      <Paper elevation={6} className="paperContainer">
        <h3 className="Bookinglist_heading">{head}</h3>

        <TableContainer component={Paper}>
          <Table size="small" aria-label="customized table">
            <TableHead>
              <TableRow>               
                <TableCell sx={{ StyledTableCell }} align="left">
                  Invoice Number
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Invoice Date
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                 Ticket Numbers
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Invoice Amount
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="left">
                  Status
                </TableCell>
                <TableCell sx={{ StyledTableCell }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow sx={{ StyledTableRow }} key={row.id}>                    
                    <TableCell sx={{ StyledTableCell }} align="left">
                      {row.number}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">
                      {row.date_invoice}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">
                      {row.ticket_numbers}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">
                      {row.amount_total}
                    </TableCell>
                    <TableCell sx={{ StyledTableCell }} align="left">
                      {row.state}
                    </TableCell>

                    <TableCell sx={{ StyledTableCell }} align="center">
                      {(() => {
                        return (
                          <Button
                            variant="contained"
                            onClick={() => {
                              generateInvoice(row.id,row.number);
                            }}
                          >
                            Download
                          </Button>
                        );
                      })()}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ pagination }}
          labelRowsPerPage=""
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
